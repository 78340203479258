<template>
    <content-wrapper>
        <page-title title="Coupons">
            <button @click.prevent="showCreateCouponModal = true" class="btn btn-secondary font-small elevation-2 pt-2 pb-2 ml-2">
                <i class="mdi mdi-plus-circle"></i> Create Coupon
            </button>
        </page-title>
    
        <div class="campaign-filters mb-3">
            <div class="row align-items-center">
                <div class="col-md-8 mb-3">
                    <div class="btn-group">
                        <button
                            type="button"
                            class="btn status-btn accent text-black active dropdown-toggle text-capitalize font-small p-2 pl-4 pr-4"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false">
                            Status: {{ params.status ? params.status.name : 'All' }}
                        </button>
                        <div class="dropdown-menu font-small">
                            <a class="dropdown-item text-capitalize" href="#" v-for="status in statuses" @click.prevent="params.type = status">
                                {{ status }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 d-flex mb-3">
                    <table-search-input
                        class="flex-grow-1"
                        v-model="params.keyword"
                        @search="performFiltering"
                        placeholder="Search by keywords...">
                    </table-search-input>
                </div>
            </div>
        </div>
    
        <div class="table-responsive bg-white  elevation" v-if="all.length">
            <table class="table m-0">
                <thead class="bg-secondary text-white">
                    <th>Name</th>
                    <th>Code</th>
                    <th>Type</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Status</th>
                    <th>Actions</th>
                </thead>
                <tbody>
                    <coupon-item
                        v-for="(item, index) in all"
                        :key="index"
                        :item="item"
                        @edit="edit"
                        @view="view"/>
                </tbody>
            </table>
        </div>
    
        <app-card v-if="!all.length">
            <empty title="No Ad found"/>
        </app-card>
    
        <create-coupon-modal 
            :show="showCreateCouponModal" 
            @cancel="showCreateCouponModal = false"/>
    </content-wrapper>
</template>
    
<script>
    import bus from '@/event-bus.js';
    import config from '@/config.js';
    import { mapActions, mapGetters } from 'vuex';
    import CouponItem from '@/views/merchant/coupons/partials/CouponItem.vue';
    import CreateCouponModal from '@/views/merchant/coupons/partials/CreateCoupon.vue';
    
    export default {
        props: ['campaign'],
    
        components: {
            CouponItem,
            CreateCouponModal
        },
    
        data() {
            return {
                params: {
                    status: '',
                    keyword: '',
                },
                selectedCoupon: null,
                showEditCouponModal: false,
                showCreateCouponModal: true,
            }
        },
    
        computed: {
            ...mapGetters({
                all: 'Coupons/getAll',
                pageDetails: 'Coupons/getPageDetails'
            }),

            statuses() {
                return config.coupons.statuses;
            }
        },
    
        watch: {
        },
    
        methods:{
            ...mapActions({
                index: 'Coupons/index',
                store: 'Coupons/store'
            }),
    
            performFiltering() {
                this.loadData({clearState: true});
            },
    
            edit(coupon) {
                if (!coupon) {
                    this.selectedCoupon = null;
                    this.showEditCouponModal = false;
                    return;
                }
    
                this.selectedCoupon = coupon;
                this.showEditCouponModal = true;
            },
    
            view(coupon) {
                this.selectedCoupon = coupon;
                this.showEditCouponModal = true;
            },
    
            loadData(query) {
                let params = {
                    paginate: true,
                    per_page: 20,
                    ...query,
                    ...this.params,
                }
    
                this.loading = true;
    
                this.showLoader('Loading coupons... Please wait');
    
                this.index(params)
                    .then(response => {
                        this.hideLoader();
                        this.loading = false;
                    })
                    .catch(error => {
                        this.loading = false;
                        this.hideLoader();
                    })
            },
    
            storeCoupon() {
                this.showLoader('Creating ad... Please wait');
    
                this.store().then(response => {
                    this.hideLoader();
                    this.edit(response);
                    this.loadData({clearState: true});
                })
                .catch(error => {
                    this.hideLoader();
    
                    this.swalError(error.message);
                });
            },
        },
    
        created() {
            this.loadData({clearState: true});
        },
    }
</script>