<template>
    <div class="form-group">
        <label class="form-label" v-if="!noLabel" :class="labelClass" :for="id">
            {{label}}

            <a
                v-if="tooltip"
                href="#"
                :id="reference"
                @click.prevent="showTooltip">
                <span class="mdi mdi-help-circle"></span>
            </a>

            <span class="text-danger" v-if="required">*</span>
            <span class="text-muted" v-if="optional">(Optional)</span>
        </label>

        <input
            :type="type"
            class="form-control font-small"
            :class="{'padded': !!error, 'disabled': disabled}"
            :disabled="disabled"
            :placeholder="placeholder"
            @input="onInput"
            :value="value"
            :name="name"
            :id="id"
            :ref="reference"
            @focus="$emit('focus')"
            @keypress.enter="$emit('enter')"
            :min="min"
            :max="max"
            :autocomplete="autocomplete"
        >

        <div class="icon" v-if="icon">
            <span :class="`${icon} ${error ? 'shake-bell text-danger' : ''}`"></span>
        </div>

        <span class="font-10 error-label text-danger" v-if="error">{{ error }}</span>

        <p class="mb-0 fs-6 mt-1 text-muted">{{ desc }}</p>
    </div>
</template>

<script>
import Config from '@/config';
import TypeAhead from './../../mixins/typeaheads';

export default {
    props: {
        id: {
            type: String,
        },

        title: {
            type: String
        },

        value: {
            type: [String, Number]
        },

        required: {
            type: Boolean,
            default: false
        },

        optional: {
            type: Boolean,
            default: false
        },

        icon: String,

        name: {
            type: String
        },

        type: {
            type: String,
            default: 'text'
        },

        label: {
            type: String,
            default: ''
        },

        error: {
            type: String,
            default: ''
        },

        placeholder: {
            type: String,
            default: ''
        },

        noLabel: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        reference: {
            type: String
        },

        divClass: {
            type: String
        },

        desc: {
            type: String
        },

        labelClass: {
            type: String
        },

        typeahead: {
            type: Object,
            default: null
        },

        tooltip: {
            type: Boolean,
            default: false
        },

        help: {
            type: Array
        },

        noMargin: {
            type: Boolean,
            default: false
        },

        autocomplete: {
            type: String,
        },

        min: [String, Number],
        max: [String, Number],

        prepend: {
            type: Boolean,
            default: false
        },

        append: {
            type: Boolean,
            default: false
        },
    },

    mixins: [TypeAhead],

    data () {
        return {
            payload: ''
        }
    },

    methods: {
        showTooltip() {
            let el = document.querySelector(`#${this.reference}`);
            let t = document.querySelector(`#t-${this.reference}`);

            new Popper(el, t, {});
        },

        /**
         * Emits typed value
         *
         * @param {Object} $event
         * @return {Undefined}
         */
        onInput($event) {
            this.$emit('input', $event.target.value);
        },

        initInputTypeAhead() {
            if (!this.typeahead) return;

            if (!this.reference) {
                return console.error(`Ref property is required`);
            }

            this.initTypeAhead(
                this.$refs[this.reference],
                `${Config.baseURL}${this.typeahead.url}`,
                this.typeahead.options
            );
        }
    },

    mounted() {
        this.initInputTypeAhead();
    }
}
</script>

<style>

</style>
