<template>
    <div class="dropdown flex-fill">
        <button 
            :class="`btn btn-${color} w-100 dropdown-toggle text-capitalize border`" 
            type="button" :id="id" 
            data-bs-toggle="dropdown" 
            aria-haspopup="true" 
            aria-expanded="false">
            {{ value || label }} <i class="mdi mdi-chevron-down"></i>
        </button>
        <div class="dropdown-menu" :aria-labelledby="id">
            <a 
                class="dropdown-item text-capitalize" 
                v-for="(opt, index) in dropdownOptions" 
                :key="index" 
                href="#"
                @click.prevent="selectOption(opt)">
                {{ opt.name }}
            </a>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        id: {
            type: String,
            required: true
        },

        options: {
            type: Array,
            default: () => []
        },
        value: {
            type: [String, Number],
            required: true
        },
        label: {
            type: String,
        },
        color: {
            type: String,
            default: 'light'
        }
    },

    computed: {
        dropdownOptions() {
            return this.options.map(option => {
                const data = {};
                if (['number', 'string'].includes(typeof option)) {
                    data.name = option;
                    data.id = option;
                } else {
                    data.name = option.name;
                    data.id = option.id;
                }
                return data;
            })
        }
    },

    methods: {
        selectOption(option) {
            this.$emit('input', option.id);
        }
    },

    mounted() {
        // console.log(this.dropdownOptions)
    }
}
</script>