import bus from '@/event-bus.js';
import Service from '@/services/campaigns';

const state = {
    all: [],
    pageDetails: {
        to: 0,
        from: 0,
        total: 0,
        perPage: 0,
        lastPage: 0,
        currentPage: 0,
    },

    objectives: [],

    currentCampaign: null
};

const getters = {
    getAll(state) {
        return state.all;
    },

    getPageDetails(state) {
        return state.pageDetails;
    },

    getObjectives(state) {
        return state.objectives;
    },

    getCurrentCampaign(state) {
        return state.currentCampaign;
    }
};

const mutations = {
    SET(state, { clearState, payload }) {
        state.pageDetails = {
            total: payload.total || 0,
            from: payload.from || 0,
            to: payload.to || 0,
            currentPage: payload.current_page || 0,
            lastPage: payload.last_page || 0,
            perPage: payload.per_page || 0
        }

        let all = payload.data ? payload.data : payload;

        if (clearState) {
            state.all = all;
        } else {
            state.all = [...state.all, ...all];
        }
    },

    ADD(state, advert) {
        state.all.unshift(advert);
    },

    UPDATE(state, response) {
        for (let [index, advert] of state.all.entries()) {
            if (advert.id == response.id) {
                state.all[index] = response;
                return;
            }
        }
    },

    REMOVE(state, id) {
        for (let [index, advert] of state.all.entries()) {
            if (advert.id == id) {
                state.all.splice(index, 1);
                return;
            }
        }
    },

    SET_CAMPAIGN(state, payload) {
        state.currentCampaign = payload;
    },

    SET_AUDIENCE(state, audience) {
        state.currentCampaign.audience = audience;
    },

    SET_POLLS(state, poll) {
        if (!state.currentCampaign) return;

        state.currentCampaign.polls.unshift(poll);
        state.currentCampaign.poll = poll;
    },

    UPDATE_ADVERT(state, payload) {
        if (!state.currentCampaign) return;

        if (state.currentCampaign.main_advert_id == payload.id) {
            bus.$set(state.currentCampaign, 'advert', payload);
            return;
        }

        for (let [index, advert] of state.currentCampaign.related_ads.entries()) {
            if (advert.id == payload.id) {
                bus.$set(state.currentCampaign.related_ads, index, payload);
                return;
            }
        }
    }
};

const actions = {
    index({ commit }, params) {
        return Service.index(params)
            .then(response => {
                commit('SET', { clearState: params.clearState, payload: response });
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    store({ commit }, data) {
        return Service.store(data)
            .then(response => {
                // commit('ADD', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    update({ commit }, params) {
        return Service.update(params.id, params.data)
            .then(response => {
                commit('SET_CAMPAIGN', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    show({ commit }, params) {
        return Service.show(params.id, params.params)
            .then(response => {
                commit('SET_CAMPAIGN', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    destroy({ commit }, id) {
        return Service.destroy(id)
            .then(response => {
                commit('REMOVE', id);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    getObjectives({ commit }, params) {
        return Service.getObjectives(params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    createThumbnailData({ commit }, params) {
        return Service.createThumbnailData(params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    publish({ commit }, data) {
        return Service.publish(data)
            .then(response => {
                commit('SET_CAMPAIGN', response);

                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    deactivate({ commit }, data) {
        return Service.deactivate(data)
            .then(response => {
                commit('SET_CAMPAIGN', response);

                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    getMatchingStrategies({commit}, params) {
        return Service.getMatchingStrategies(params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    storeImpressions({commit}, data) {
        return Service.storeImpressions(data)
            .then(response => {
                commit('Statuses/SET_IMPRESSIONS', data.ids, { root: true });

                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}