<template>
    <ul class="nav nav-tabs nav-bordered">
        <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
            <a href="#" @click.prevent="setTab(tab)" class="nav-link" :class="{'active': value === tab.id}">
                {{ tab.name }}
            </a>
        </li>
    </ul>
</template>
<script>
export default {
    props: {
        options: {
            type: Array,
            default: () => []
        },
        value: {
            type: String,
            default: ''
        },
    },

    computed: {
        tabs() {
            return this.options.map(option => {
                let data = null;
                if (['number', 'string'].includes(typeof option)) {
                    data = { name: option, id: option };
                } else if (typeof option === 'object') {
                    data = {...option};
                }
                return data;
            });
        }
    },

    methods: {
        setTab(tab) {
            this.$emit('input', tab.id);
        }
    }
}
</script>