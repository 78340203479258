<template>
    <content-wrapper>
        <page-title title="Audience Insights">
            <div class="d-flex align-items-end">
                <button @click.prevent="createAudience" class="btn btn-soft-success">
                    <i class="mdi mdi-plus-circle"></i> Create Audience
                </button>
            </div>
        </page-title>

        <div class="row mb-5">
            <div class="col-md-3">
                <insight-filters @search="loadInsights" v-model="filters"/>
            </div>
            <div class="col-md-9">
                <base-tab v-model="activeTab" :options="tabs" class="mb-3"/>

                <insight-charts 
                    v-if="isInsights"
                    :isLoadingInsights="isLoadingInsights"
                    :insightsData="insightsData"/>

                <listings :filters="filters" v-if="filters && isListing"/>
            </div>
        </div>

        <add-audience 
            :show="showAddAudience" 
            :filters="filters"
            @cancel="showAddAudience = false"
        />
    </content-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddAudience from './partials/AddAudience';
import InsightCharts from './partials/InsightCharts.vue';
import InsightFilters from './partials/InsightFilters.vue';
import Listings from './Listings.vue'

export default {
    components: {
        Listings,
        AddAudience,
        InsightCharts,
        InsightFilters,
    },

    data() {
        return {
            activeTab: 'insights',
            showAddAudience: false,
            isLoadingInsights: false,
            filters: null,
            audience: null,
            insightsData: {
                age_and_gender: null,
            },
            statuses: [
                {name: 'All', value: 'all', icon: 'mdi-filter'},
                {name: 'Active', value: 'active', icon: 'mdi-check-circle'},
                {name: 'Inactive', value: 'inactive', icon: 'mdi-close-circle'},
            ]
        }
    },

    computed: {
        ...mapGetters({
            all: 'Audience/getAll',
            pageDetails: 'Audience/getPageDetails'
        }),

        isInsights() {
            return this.activeTab === 'insights';
        },

        isListing() {
            return this.activeTab === 'listings';
        },

        tabs() {
            return [
                { id: 'insights', name: 'Insights'},
                { id: 'listings', name: 'Existing Audience'},
            ]
        },
    },

    methods:{
        ...mapActions({
            getInsights: 'Audience/getInsights',
        }),
        
        createAudience() {
            this.showAddAudience = true;
        },

        

        setInsightsData(data) {
            this.insightsData.age_and_gender = data.age_and_gender;
        },

        loadInsights(params) {
            this.isLoadingInsights = true;
            
            params = {...this.filters, ...params}

            this.getInsights(params).then((response) => {
                this.isLoadingInsights = false;
                this.setInsightsData(response);
            }).catch(() => {
                this.isLoadingInsights = false;
            });
        }
    },

    created() {
    }
}
</script>