<template>
    <div>
        <div class="row">
            <div class="col-md-4" v-for="(audience, index) in all" :key="index">
                <audience-item :audience="audience"/>
            </div>
        </div>

        <empty title="No audience found" v-if="isEmpty"/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddAudience from './partials/AddAudience';
import AudienceItem from './partials/AudienceItem.vue';

export default {
    props: ['filters'],
    components: {
        AddAudience,
        AudienceItem,
    },

    data() {
        return {
            keyword: '',
        }
    },

    computed: {
        ...mapGetters({
            all: 'Audience/getAll',
            pageDetails: 'Audience/getPageDetails'
        }),

        isEmpty() {
            return this.all.length === 0;
        }
    },

    methods:{
        ...mapActions({
            index: 'Audience/index'
        }),

        loadData(query) {
            let params = {
                ...this.filters,
                ...query,
            }

            this.index(params)
                .then(response => {
                    console.log(response)
                })
                .catch(error => null)
        }
    },

    created() {
        this.loadData({clearState: true});
    }
}
</script>