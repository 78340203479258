<template>
    <content-wrapper>
        <page-title title="View Order">
            <button class="btn btn-soft-success" @click.prevent="editProd">
                <i class="mdi mdi-square-edit-outline"></i> Update Product
            </button>
        </page-title>

        <template v-if="product">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-5">
    
                                    <div class="tab-content pt-0">
                                        <div v-for="(file, index) in mediaFiles" :key="index" 
                                            class="tab-pane"
                                            :class="{'active show': file.id === product.cover_media_file_id}" 
                                            :id="`product-${index}-item`">
                                            <img 
                                                :src="file.url" 
                                                :alt="file.name" 
                                                class="img-fluid mx-auto d-block rounded object-fit-contain"
                                                style="height:40vh">
                                        </div>
                                    </div>
    
                                    <ul class="nav nav-pills nav-justified mt-3">
                                        <li class="nav-item" v-for="(file, index) in mediaFiles" :key="index">
                                            <a :href="`#product-${index}-item`" 
                                                data-bs-toggle="tab" 
                                                aria-expanded="false" 
                                                class="nav-link product-thumb"
                                                :class="{'active show': file.id === product.cover_media_file_id}">
                                                <img :src="file.url" 
                                                    :alt="file.name" 
                                                    class="img-fluid mx-auto d-block rounded avatar-xl">
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-lg-7">
                                    <div class="ps-xl-3 mt-3 mt-xl-0">
                                        <a href="#" class="text-primary">
                                            {{ product.catalog.name }}
                                        </a>
                                        <h4 class="mb-3">{{ product.name }}</h4>
                                        
                                        <div class="d-flex mb-1">
                                            <p class="text-muted me-3">
                                                <span class="mdi mdi-star text-warning"></span>
                                                <span class="mdi mdi-star text-warning"></span>
                                                <span class="mdi mdi-star text-warning"></span>
                                                <span class="mdi mdi-star text-warning"></span>
                                                <span class="mdi mdi-star"></span>
                                            </p>
                                            <p class="">
                                                <a href="" class="text-muted">( 36 Customer Reviews )</a>
                                            </p>
                                        </div>

                                        <h6 class="text-danger text-uppercase">
                                            {{ product.objective.name }}
                                        </h6>

                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <h4 class="text-primary">
                                                <span>{{product.currency}} {{ product.price | moneyFormat }}</span>
                                            </h4>
    
                                            <h4 class="mb-2">
                                                <span class="badge bg-soft-success text-success" v-if="isInStock">In Stock</span>
                                                <span class="badge bg-soft-warning text-warning" v-if="isLowStock">Low in Stock</span>
                                                <span class="badge bg-soft-danger text-danger" v-if="isOutOfStock">Out of Stock</span>
                                                <span class="badge bg-soft-info text-info" v-if="isPreorder">Only Preorder</span>
                                            </h4>
                                        </div>

                                        <p class="text-muted mb-3">
                                            {{ product.description }}
                                        </p>

                                        <div class="mb-3" v-if="product.features.categories.length">
                                            <h5 class="text-muted">Categories</h5>
                                            <div class="d-flex flex-wrap gap-1 mb-2">
                                                <div v-for="(category, index) in product.features.categories" :key="index" 
                                                    class="badge bg-light border text-primary text-capitalize p-1 fs-6">
                                                    {{ category.name }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mb-3" v-if="product.features.brands.length">
                                            <h5 class="text-muted">Brands</h5>
                                            <div class="d-flex flex-wrap gap-1 mb-2">
                                                <div v-for="(brand, index) in product.features.brands" :key="index" 
                                                    class="badge bg-light border text-primary text-capitalize p-1 fs-6">
                                                    {{ brand.name }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mb-3" v-if="product.features.materials.length">
                                            <h5 class="text-muted">Materials</h5>
                                            <div class="d-flex flex-wrap gap-1 mb-2">
                                                <div v-for="(material, index) in product.features.materials" :key="index" 
                                                    class="badge bg-light border text-primary text-capitalize p-1 fs-6">
                                                    {{ material.name }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mb-3" v-if="product.features.colors.length">
                                            <h5 class="text-muted">Colors</h5>
                                            <div class="d-flex flex-wrap gap-1 mb-2">
                                                <div v-for="(color, index) in product.features.colors" :key="index" 
                                                    class="badge bg-light border text-primary text-capitalize p-1 d-flex gap-1 align-items-center fs-6">
                                                    <div :style="{background: color.hex}" class="avatar-xs rounded-circle d-inline-flex"></div>
                                                    {{ color.name }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-3">
                                            <div class="col-md-6" v-if="product.claims.length">
                                                <h5 class="text-muted">Promotional Claims</h5>
                                                <p class="text-muted" v-for="(claim, index) in product.claims" :key="index">
                                                    <i class="mdi mdi-checkbox-marked-circle-outline h6 text-primary"></i> 
                                                    {{ claim }}
                                                </p>
                                            </div>
                                            <div class="col-md-6" v-if="product.keywords.length">
                                                <h5 class="text-muted">Keywords</h5>
                                                <p class="text-muted" v-for="(keyword, index) in product.keywords" :key="index">
                                                    <i class="mdi mdi-checkbox-marked-circle-outline h6 text-primary"></i> 
                                                    {{ keyword }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <add-manually 
                :catalog_id="product.catalog_id"
                :show="showEditForm"
                @cancel="showEditForm = false"/>
        </template>

        <empty title="Product Not Found" v-if="!product"/>

    </content-wrapper>
</template>
<script>
import { get } from 'lodash'
import { mapActions } from 'vuex'
import AddManually from '@/views/merchant/catalogs/partials/add-products/AddManually.vue';

export default {
    props: ['product_id'],

    components: {
        AddManually
    },

    data() {
        return {
            product: null,
            showEditForm: false
        }
    },

    computed: {
        mediaFiles() {
            return get(this.product, 'media_files', [])
        },
        status() {
            return get(this.product, 'status')
        },
        isInStock() {
            return this.status === 'in-stock'
        },
        isOutOfStock() {
            return this.status === 'out-of-stock'
        },
        isLowStock() {
            return this.status === 'low-stock'
        },
        isPreorder() {
            return this.status === 'preorder'
        }
    },

    methods: {
        ...mapActions({
            show: 'Products/show',
            seedProduct: 'ProductsCreate/seedProduct',
        }),

        editProd() {
            this.seedProduct(this.product);
            this.showEditForm = true;
        },

        loadProduct() {
            this.showLoader('Loading product details...')
            this.show({ id: this.product_id })
            .then(response => {
                this.hideLoader()
                this.product = response
                console.log(response)
            }).catch(error => {
                this.hideLoader()
            })
        }
    },

    created() {
        this.loadProduct()
    }
}
</script>