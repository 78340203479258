import { axios } from './index';

export default {
    index(params) {
        return axios.get('locations/countries', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    getStates(params) {
        return axios.get('locations/states', {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    getStates(params) {
        return axios.get('locations/cities', {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    }
}