import Service from '@/services/coupons';

const state = {
    all: [],
    pageDetails: {
        to: 0,
        from: 0,
        total: 0,
        perPage: 0,
        lastPage: 0,
        currentPage: 0,
    },
};

const getters = {
    getAll(state) {
        return state.all;
    },

    getPageDetails(state) {
        return state.pageDetails;
    },
};

const mutations = {
    SET_LISTING(state, { clearState, payload }) {
        state.pageDetails = {
            total: payload.total || 0,
            from: payload.from || 0,
            to: payload.to || 0,
            currentPage: payload.current_page || 0,
            lastPage: payload.last_page || 0,
            perPage: payload.per_page || 0
        }

        let all = payload.data ? payload.data : payload;

        if (clearState) {
            state.all = all;
        } else {
            state.all = [...state.all, ...all];
        }
    },
};

const actions = {
    index({ commit }, params) {
        return Service.index(params)
            .then(response => {
                commit('SET_LISTING', { clearState: params.clearState, payload: response });
                return Promise.resolve(response);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    store({ commit }, data) {
        return Service.store(data)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    update({ commit }, params) {
        return Service.update(params.id, params.data)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    show({ commit }, params) {
        return Service.show(params.id, params.params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    destroy({ commit }, id) {
        return Service.destroy(id)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}