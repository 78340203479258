<template>
    <content-wrapper>
        <page-title title="Products">
            <div class="btn-group dropstart">
                <div class="btn-group">
                    <div class="btn-group dropstart" role="group">
                        <button type="button" class="btn btn-success dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-chevron-left"></i>
                        </button>
                        <div class="dropdown-menu" style="">
                            <a class="dropdown-item" @click.prevent="openAddProductModal({option: 'manual'})" href="#">
                                <i class="mdi mdi-format-float-left me-1"></i> Add Manually
                            </a>
                            <a class="dropdown-item" @click.prevent="openAddProductModal({option: 'file'})" href="#">
                                <i class="mdi mdi-file-document-outline me-1"></i> Add Items from a File
                            </a>
                            <a class="dropdown-item" @click.prevent="openAddProductModal({option: 'external'})" href="#">
                                <i class="mdi mdi-link-variant me-1"></i> Connect to External Platform
                            </a>
                        </div>
                    </div>
                    <button @click.prevent="addProduct(true)" type="button" class="btn btn-soft-success waves-effect waves-light">
                        <i class="mdi mdi-plus-circle me-1"></i> Add New
                    </button>
                </div>
            </div>
        </page-title>
        
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-between align-items-center">
                            <div class="col-12">
                                <form class="row">
                                    <div class="col-md-3">
                                        <base-select label="Catalog" v-model="catalogId">
                                            <option v-for="(catalog, index) in catalogs" 
                                                :key="index" 
                                                :selected="catalog.id == catalogId"
                                                :value="catalog.id">
                                                {{ catalog.name }}
                                            </option>
                                        </base-select>
                                    </div>

                                    <div class="col-md-6">
                                        <base-input label="Search" placeholder="Search Products..." />
                                    </div>

                                    <div class="col-md-3">
                                        <base-select v-model="sortBy" label="Sort By">
                                            <option value="-created_at">Recent</option>
                                            <option value="-order_count">Popular</option>
                                            <option value="-rating">Rating</option>
                                            <option value="+price">Price Low</option>
                                            <option value="-price">Price High</option>
                                        </base-select>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-lg-4 col-xl-3" v-for="(product, index) in products" :key="index">
                <product-item :product="product" @edit="editProd(product)"/>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <ul class="pagination pagination-rounded justify-content-end mb-3">
                    <li class="page-item">
                        <a class="page-link" href="javascript: void(0);" aria-label="Previous">
                            <span aria-hidden="true">«</span>
                            <span class="visually-hidden">Previous</span>
                        </a>
                    </li>
                    <li class="page-item active"><a class="page-link" href="javascript: void(0);">1</a></li>
                    <li class="page-item"><a class="page-link" href="javascript: void(0);">2</a></li>
                    <li class="page-item"><a class="page-link" href="javascript: void(0);">3</a></li>
                    <li class="page-item"><a class="page-link" href="javascript: void(0);">4</a></li>
                    <li class="page-item"><a class="page-link" href="javascript: void(0);">5</a></li>
                    <li class="page-item">
                        <a class="page-link" href="javascript: void(0);" aria-label="Next">
                            <span aria-hidden="true">»</span>
                            <span class="visually-hidden">Next</span>
                        </a>
                    </li>
                </ul>
            </div> <!-- end col-->
        </div>

        <create-product-options 
            :show="showCreateProductOptions"
            @submit="openAddProductModal"
            @cancel="addProduct(false)"/>

        <add-manually 
            :catalog_id="catalogId"
            :show="showManualForm"
            @cancel="showManualForm = false"/>

        <add-from-file 
            :catalog_id="catalogId"
            :show="showFileForm"
            @cancel="showFileForm = false"/>

    </content-wrapper>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import ProductItem from '@/views/merchant/catalogs/partials/ProductItem.vue';
import CreateProductOptions from '@/views/merchant/catalogs/partials/CreateProductOptions.vue';
import AddManually from '@/views/merchant/catalogs/partials/add-products/AddManually.vue';
import AddFromFile from '@/views/merchant/catalogs/partials/add-products/AddFromFile.vue';

export default {
    components: {
        ProductItem,
        AddManually,
        AddFromFile,
        CreateProductOptions
    },

    data() {
        return {
            catalogId: null,
            sortBy: '-created_at',

            showCreateProductOptions: false,
            showManualForm: false,
            showFileForm: false,
        }
    },

    computed: {
        ...mapGetters({
            catalogs: 'Catalogs/getAll',
            products: 'Products/getAll',
            pageDetails: 'Products/getPageDetails'
        })
    },

    watch: {
        catalogId() {
            this.saveLastCatalog(this.catalogId);
            this.loadData({clearState: true});
        }
    },

    methods: {
        ...mapActions({
            fetchCatalogs: 'Catalogs/index',
            index: 'Products/index',
            seedProduct: 'ProductsCreate/seedProduct',
            getShoppingObjectives: 'ShoppingObjectives/index'
        }),

        editProd(product) {
            this.seedProduct(product);
            this.openAddProductModal({option: 'manual'});
        },

        openAddProductModal(params) {
            if (params.option === 'manual') {
                this.showManualForm = true;
            } else if (params.option === 'file') {
                this.showFileForm = true;
            } else if (params.option === 'external') {
                console.log('Connect to external platform');
            }
        },

        saveLastCatalog(id) {
            localStorage.setItem('last-cat-id', id);
        },

        addProduct(show = true) {
            this.showCreateProductOptions = show;
        },

        loadData(query) {
            let params = {
                ...query,
                catalog_id: this.catalogId,
                sort_by: this.sortBy
            }

            this.loading = true;

            this.showLoader('Loading products... Please wait');

            this.index(params)
                .then(response => {
                    this.hideLoader();
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.hideLoader();
                })
        },
    },

    created() {
        // setTimeout(() => {
        //     this.showFileForm = true;
        // }, 500);

        const routeCatalogId = this.$route.query.catalog;

        if (routeCatalogId) {
            this.catalogId = routeCatalogId;
            this.saveLastCatalog(routeCatalogId)
        } else {
            const id = localStorage.getItem('last-cat-id');
            if (id) {
                this.catalogId = id;
            }
        }

        if (!this.catalogId) {
            return this.$router.push({name: 'catalogs'});
        }

        this.fetchCatalogs({clearState: true, status: 'all'});

        this.loadData({clearState: true});

        this.getShoppingObjectives().catch(error => null);
    }
}
</script>