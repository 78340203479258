<template>
    <div class="">
        <label class="form-label" v-if="!noLabel" :class="labelClass">
            {{label}}
            
            <a
                v-if="tooltip"
                href="#"
                :id="reference"
                @click.prevent="showTooltip">
                <span class="mdi mdi-help-circle"></span>
            </a>

            <span :id="`t-${reference}`"></span>

            <span class="text-danger" v-if="required">*</span>
            <span class="text-muted" v-if="optional">(Optional)</span>
        </label>

        <input
            :type="type"
            class="form-control"
            :class="{'padded': !!error, 'text-input': isTextInput, 'disabled': disabled}"
            :disabled="disabled"
            :placeholder="placeholder"
            :name="name"
            ref="date_range"
            @focus="$emit('focus')"
            @keypress.enter="$emit('enter')"
        >

        <div class="icon" v-if="icon">
            <span :class="`${icon} ${error ? 'shake-bell text-danger' : ''}`"></span>
        </div>

        <span class="text-danger" v-if="error">{{ error }}</span>
    </div>
</template>

<script>
import moment from 'moment';

export default {
    props: {
        value: {
            type: [String, Number]
        },

        required: {
            type: Boolean,
            default: false
        },

        optional: {
            type: Boolean,
            default: false
        },

        typeNumber: {
            type: [Number, String],
            default: 4
        },

        icon: String,

        isTextInput: {
            type: Boolean,
            default: true
        },

        name: {
            type: String,
            required: true
        },

        type: {
            type: String,
            default: 'text'
        },

        label: {
            type: String,
            default: ''
        },

        error: {
            type: String,
            default: ''
        },

        placeholder: {
            type: String,
            default: ''
        },

        noLabel: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        reference: {
            type: String
        },

        divClass: {
            type: String
        },

        desc: {
            type: String
        },

        labelClass: {
            type: String
        },

        tooltip: {
            type: Boolean,
            default: false
        },

        help: {
            type: Array
        },

        range: {
            type: String,
            default: 'Last 30 Days'
        },

        placement: {
            type: String,
            default: 'left'
        },

        noRanges: {
            type: Boolean,
            default: false
        },

        autoUpdate: {
            type: Boolean,
            default: true
        },

        drops: {
            type: String,
            default: 'auto' // [down, up, auto]
        }
    },

    data () {
        return {
            payload: ''
        }
    },

    computed: {
        format() {
            return `YYYY-MM-DD HH:mm:ss`
        },

        ranges() {
            return {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'Last 3 Months': [moment().subtract(3, 'months').startOf('month'), moment().endOf('month')],
                'Last 6 Months': [moment().subtract(6, 'months').startOf('month'), moment().endOf('month')],
                'Last 12 Months': [moment().subtract(12, 'months').startOf('month'), moment().endOf('month')],
            }
        }
    },

    methods: {
        showTooltip() {
            let el = document.querySelector(`#${this.reference}`);
            let t = document.querySelector(`#t-${this.reference}`);

            new Popper(el, t, {});
        },

        onInput(startDate = null, endDate = null) {
            let value = '';

            if (startDate && endDate) {
                value = `${startDate.format(this.format)} to ${endDate.format(this.format)}`
            }

            this.$emit('input', value);
            
            return value;
        },

        setup() {
            this.$nextTick().then(() => {
                const options = {
                    opens: this.placement,
                    drops: this.drops,
                    autoUpdateInput: this.autoUpdate,
                    locale: { 
                        format: 'Do MMM, YYYY',
                        cancelLabel: 'Clear'
                    }
                };

                if (!this.noRanges) {
                    let range;
    
                    if (Object.keys(this.ranges).includes(this.range)) {
                        range = this.range;
                    } else {
                        range = 'Last 30 Days';
                    }
    
                    let [startDate, endDate] = this.ranges[range];
    
                    startDate = startDate.startOf('day');
                    endDate = endDate.endOf('day');
    
                    this.onInput(startDate, endDate)

                    options.startDate = startDate;
                    options.endDate = endDate;
                    options.ranges = this.ranges;
                }
                
                const element = $(this.$refs.date_range)
                element.daterangepicker(options);

                element.on('apply.daterangepicker', (ev, picker) => {
                    this.onInput(picker.startDate, picker.endDate);
                    const format = options.locale.format;
                    element.val(
                        picker.startDate.format(format) + ' - ' + picker.endDate.format(format)
                    );
                });

                element.on('cancel.daterangepicker', () => {
                    this.onInput();
                    element.val('');
                });
            })
        }
    },

    mounted() {
        this.setup();
    }
}
</script>

<style>

</style>
