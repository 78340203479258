import Vue from 'vue';
import Service from '@/services/locations';

const state = {
    countries: [],
    states: {},
    cities: {},
};

const getters = {
    getCountries(state) {
        return state.countries;
    },

    getStates(state) {
        return state.states;
    },

    getCities(state) {
        return state.cities;
    }
};

const mutations = {
    SET_COUNTRIES(state, payload) {
        Vue.set(state, 'countries', payload.data ? payload.data : payload);
    },

    SET_STATES(state, {country_id, states}) {
        Vue.set(state.states, country_id, states);
    },

    SET_CITIES(state, {state_id, cities}) {
        Vue.set(state.cities, state_id, cities);
    }
};

const actions = {
    index({ commit }, params) {
        return Service.index(params)
            .then(response => {
                commit('SET_COUNTRIES', response);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    getStates({ commit }, params) {
        return Service.getStates(params)
            .then(response => {
                commit('SET_STATES', { country_id: params.country_id, states: response });
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    getCities({ commit }, params) {
        return Service.getCities(params)
            .then(response => {
                commit('SET_CITIES', { state_id: params.state_id, cities: response });
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    }
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}