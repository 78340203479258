<template>
<content-wrapper>
    <page-title title="Ads">
        <button @click.prevent="createAdvert" class="btn btn-soft-success">
            <i class="mdi mdi-plus-circle"></i> Create Advert
        </button>
    </page-title>

    <div class="campaign-filters mb-3">
        <div class="row align-items-center">
            <div class="col-md-8 mb-3">
                <div class="btn-group">
                    <button
                        v-for="(status, index) in statuses"
                        @click.prevent="setStatus(status.value)"
                        :class="{'text-primary': params.status == status.value}"
                        class="btn btn-secondary-success bg-white rounded-0"
                        type="button">
                        <i class="mdi" :class="status.icon"></i> {{ status.name }}
                    </button>
                </div>
            </div>
            <div class="col-md-4 d-flex mb-3">
                <form class="search-bar w-100">
                    <div class="position-relative">
                        <input v-model="params.keyword" 
                            type="text" class="form-control form-control-white" 
                            placeholder="Search by keyword...">
                        <span class="mdi mdi-magnify"></span>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4 col-sm-6 col-6" v-for="(ad, index) in all" :key="index">
            <ad-item :advert="ad" @edit="edit(ad)" @refresh="loadData({clearState: true})"/>
        </div>
    </div>

    <empty v-if="!all.length" title="No Ad found"/>

    <create-ad 
        :show="showAdvert" 
        :advert="editableAdvert"
        @cancel="edit(null)" 
        @refresh="loadData({clearState: true})"/>

</content-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import AdItem from '@/views/merchant/adverts/partials/AdItem.vue';
import CreateAd from '@/views/merchant/adverts/partials/CreateAdvert.vue';

export default {
    components: {
        AdItem,
        CreateAd,
    },

    data() {
        return {
            showAdvert: false,
            editableAdvert: null,
            statuses: [
                {name: 'All', value: 'all', icon: 'mdi-format-list-bulleted'},
                {name: 'Launched', value: 'active', icon: 'mdi-radiobox-marked'},
                {name: 'Paused', value: 'paused', icon: 'mdi-stop-circle-outline'},
                {name: 'Draft', value: 'draft', icon: 'mdi-paperclip'},
                {name: 'Archived', value: 'archived', icon: 'mdi-archive'},
                {name: 'Trashed', value: 'trashed', icon: 'mdi-trash-can'},
            ],
            params: {
                status: 'all',
                keyword: '',
            }
        }
    },

    computed: {
        ...mapGetters({
            all: 'Adverts/getAll',
            pageDetails: 'Adverts/getPageDetails'
        })
    },

    watch: {
    },

    methods:{
        ...mapActions({
            index: 'Adverts/index',
            store: 'Adverts/store',
            fetchCatalogs: 'Catalogs/index',
        }),

        setStatus(value) {
            this.params.status = value;
            this.performFiltering();
        },

        performFiltering() {
            this.loadData({clearState: true});
        },

        edit(advert) {
            if (!advert) {
                this.editableAdvert = null;
                this.showAdvert = false;
                return;
            }

            this.editableAdvert = advert;
            this.showAdvert = true;
        },

        view(advert) {
            this.viewableAdvert = advert;
            this.showViewAdvertModal = true;
        },

        loadData(query) {
            let params = {
                paginate: true,
                per_page: 20,
                ...query,
                ...this.params,
                keyword: this.keyword
            }

            this.loading = true;

            this.showLoader('Loading ads... Please wait');

            this.index(params)
                .then(response => {
                    this.hideLoader();

                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.hideLoader();
                })
        },

        createAdvert() {
            this.showAdvert = true;
        },

        showPartialsModal(key) {
            this.showModals[key] = true;
        },
    },

    created() {
        this.loadData({clearState: true});
        this.fetchCatalogs({clearState: true, status: 'all'});
    },
}
</script>