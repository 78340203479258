<template>
    <modal
        :show="show"
        :centered="true"
        :md="true"
        okText="Next"
        @cancel="cancel"
        okIcon="mdi mdi-plus"
        @ok="submit">
    
        <h5 class="m-0" slot="header">
            How do you want to add the product?
        </h5>

        <div class="" slot="body">
            <button 
                class="btn w-100 d-flex align-items-center p-1 pt-2 pb-2 mb-2" 
                v-for="(option, index) in options" 
                :key="index"
                @click="selectedOption = option.id"
                :class="{'bg-soft-primary': option.id === selectedOption, 'bg-light': option.id !== selectedOption}">
                <div class="icon bg-white rounded-circle p-1 me-2">
                    <i class="text-primary display-6" :class="option.icon"></i>
                </div>
                <div class="text-start">
                    <h5 class="mb-1">{{ option.title }}</h5>
                    <p class="m-0 text-muted">{{ option.description }}</p>
                </div>
            </button>
        </div>
    </modal>
    </template>
    <script>
    import { mapActions, mapGetters } from 'vuex';
    
    export default {
        props: ['show'],
    
        data() {
            return {
                selectedOption: null,
                options: [
                    {
                        id: 'manual',
                        icon: 'mdi mdi-format-float-left',
                        title: 'Add Manually',
                        description: 'Add product manually by filling out the form.'
                    },
                    {
                        id: 'file',
                        icon: 'mdi mdi-file-document-outline',
                        title: 'Add Items from a File',
                        description: 'Import products from a CSV file.'
                    },
                    {
                        id: 'external',
                        icon: 'mdi mdi-link-variant',
                        title: 'Connect to External Platform',
                        description: 'Connect to an external platform to import products.'
                    }
                ]
            }
        },
    
        methods: {    
            cancel() {
                this.$emit('cancel');
                this.selectedOption = null;
            },
    
            submit() {
                if (!this.selectedOption) {
                    return;
                }

                this.$emit('submit', {option: this.selectedOption});
                this.cancel();
            }
        }
    }
    </script>