<template>
    <div class="p-2">
        <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">Other Details</h5>

        <div class="mb-3">
            <label class="form-label">Keywords (Enter 1 or more comma-separated keywords)</label>
            <input type="text" name="keywords" class="selectize-close-btn" :value="keywords">
        </div>

        <div class="mb-3">
            <label class="form-label">Marketing Claims (Enter 1 or more comma-separated claims)</label>
            <input type="text" name="claims" class="selectize-close-btn" :value="claims">
        </div>

        <div class="row mb-3">
            <div class="col-md-4">
                <div class="">
                    <label for="objective" class="form-label">Shopping Objective <span class="text-danger">*</span></label>
                    <select v-model="objective" class="form-control" id="objective">
                        <option value="">Select Objective</option>
                        <option v-for="(objective, index) in objectives" :key="index" :value="objective.id">
                            {{ objective.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import {initSelectize} from '@/utils/forms';

export default {
    data() {
        return {
            objective: '',
        }
    },
    computed: {
        ...mapGetters({
            formValues: 'ProductsCreate/getFormValues',
            objectives: 'ShoppingObjectives/getAll'
        }),

        keywords() {
            return this.formValues.keywords.join(',');
        },

        claims() {
            return this.formValues.claims.join(',');
        }
    },

    watch: {
        'formValues.objective'() {
            this.objective = this.formValues.objective;
        },

        objective() {
            this.setFormValue({key: 'objective', value: this.objective});
        }
    },

    methods: {
        ...mapActions({
            setFormValue: 'ProductsCreate/setFormValue'
        }),

        onChange({name, value}) {
            const data = {key: name, value: value.split(',')}
            this.setFormValue(data);
        }
    },

    mounted() {
        initSelectize('.selectize-close-btn', {
            onChange: this.onChange,
        });
        this.objective = this.formValues.objective;
    }
}
</script>