<template>
    <div class="p-2">
        <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">General</h5>

        <div class="row mb-3">
            <div class="col-md-4">
                <div class="">
                    <label for="product-name" class="form-label">Name / Title <span class="text-danger">*</span></label>
                    <input v-model="payload.name" type="text" id="product-name" class="form-control" placeholder="e.g : Apple iMac">
                </div>
            </div>
            <div class="col-md-4">
                <div class="">
                    <label for="global-id" class="form-label">Global ID <span class="text-danger">*</span></label>
                    <div class="input-group">
                        <button class="btn input-group-text btn-light border waves-effect waves-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ payload.gid_type ? payload.gid_type : 'ID Type' }} <i class="mdi mdi-chevron-down"></i>
                        </button>

                        <div class="dropdown-menu" style="">
                            <a class="dropdown-item" 
                                v-for="(type, index) in globalIdTypes" 
                                @click.prevent="setGlobalId(type.id)"
                                href="#">
                                {{ type.name }}
                            </a>
                        </div>
                        <input v-model="payload.gid" type="text" id="global-id" class="form-control" placeholder="e.g: ISBN Number">
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="">
                    <label for="product-reference" class="form-label">SKU <span class="text-danger">*</span></label>
                    <input v-model="payload.sku" type="text" id="product-reference" class="form-control" placeholder="Product SKU">
                </div>
            </div>
        </div>
        
        <div class="row mb-3">
            <div class="col-md-4">
                <div class="">
                    <label for="product-price" class="form-label">Price <span class="text-danger">*</span></label>
                    <input v-model="payload.price" type="text" class="form-control" id="product-price" placeholder="Enter amount">
                </div>
            </div>
            <div class="col-md-4">
                <div class="">
                    <label for="availability" class="form-label">Availability <span class="text-danger">*</span></label>
                    <select v-model="payload.status" class="form-control" id="availability">
                        <option v-for="(avail, index) in availabilities" :key="index" :value="avail.id">
                            {{ avail.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-4">
                <div class="">
                    <label for="condition" class="form-label">Condition <span class="text-danger">*</span></label>
                    <select v-model="payload.condition" class="form-control" id="condition">
                        <option value="new">New</option>
                        <option value="new">Used</option>
                        <option value="new">Refurbished</option>
                    </select>
                </div>
            </div>
        </div>


        <div class="mb-3">
            <label for="product-description" class="form-label">Description <span class="text-danger">*</span></label>
            <div id="snow-editor" style="min-height: 100px;"></div>
        </div>
    </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import { initEditor } from '@/utils/forms'
import { createLowRes, createMedRes, createHighRes } from '@/utils/image';
import config from '@/config'

export default {
    data() {
        return {
            payload: {
                name: '',
                gid_type: '',
                gid: '',
                sku: '',
                price: '',
                status: '',
                condition: '',
                description: '',
            }
        }
    },

    computed: {
        ...mapGetters({
            formValues: 'ProductsCreate/getFormValues'
        }),

        availabilities() {
            return config.products.availabilities
        },

        globalIdTypes() {
            return config.products.globalIdTypes
        }
    }, 

    watch: {
        formValues: {
            handler() {
                this.payload = {...this.formValues}
            },
            immediate: true
        },

        payload: {
            handler() {
                this.setFormValues(this.payload)
            },
            deep: true
        }
    },

    methods: {
        ...mapActions({
            setFormValues: 'ProductsCreate/setFormValues'
        }),

        setGlobalId(id) {
            this.payload.gid_type = id
        },

        async onImageChange(e) {
            const file = e.target.files.length ? e.target.files[0] : null

            if (!file) {
                this.payload.imagePreviewUrl = null;
                this.payload.image = null;
                return;
            }

            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg']
            if (!allowedTypes.includes(file.type)) {
                return this.swalError('Invalid file type', 'Please select a valid image file')
            }

            this.payload.imagePreviewUrl = URL.createObjectURL(file)

            this.$nextTick(async () => {
                const lowRes = await createLowRes(file)
                const medRes = await createMedRes(file)
                const highRes = await createHighRes(file)

                this.payload.image = { lowRes, medRes, highRes }
                this.payload.imageName = file.name
                this.payload.imageSize = `${(file.size / 1024).toFixed(2)} KB`
            });
        },

        changeImage() {
            const label = $('label[for="prod-image"]');
            label.trigger('click')
        },

        onEditorChange(value) {
            this.payload.description = value
        },

        cancel() {
            this.$emit('cancel')
        },

        initDescriptionEditor() {
            initEditor('#snow-editor', {
                onChange: this.onEditorChange,
                value: this.payload.description
            });
        }
    },

    destroyed() {
        URL.revokeObjectURL(this.payload.imagePreviewUrl)
    },

    mounted() {
        this.initDescriptionEditor();
    }
}
</script>