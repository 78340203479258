<template>
<modal
    :show="show"
    :centered="true"
    :showFooter="true"
    :maxHeight="true"
    :showInfo="false"
    okText="Invite Member"
    @ok="submit"
    @cancel="cancel"
    okIcon="mdi mdi-email-outline">

    <h5 class="m-0 font-small font-weight-normal" slot="header">
        Invite Member
    </h5>

    <div slot="body">
        <base-input
            label="Email Address"
            type="text"
            placeholder="e.g you@example.com"
            v-model="payload.email"
            class="mb-4"
            :error="validationErrors.email"/>
        
        <base-select v-model="payload.role" label="Role" :error="validationErrors.role">
            <option value="">select role</option>
            <option value="admin">Admin</option>
            <option value="member">Member</option>
        </base-select>

        <div class="mt-4" v-if="payload.role == 'member'">
            <div class="form-check">
                <input v-model="payload.can_launch" type="checkbox" class="form-check-input" id="launch-campaigns" checked>
                <label class="form-check-label" for="launch-campaigns">Can Launch Campaigns</label>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default{
    props: ['show', 'member'],

    data() {
        return {
            payload: {
                email: '',
                role: '',
                can_launch: false
            },

            validationErrors: {
                email: '',
                role: '',
                can_launch: false
            }
        }
    },

    computed: {
        ...mapGetters({
            countries: 'Countries/getAll'
        })
    },

    methods: {
        ...mapActions({
            store: 'MerchantTeamMembers/store',
            update: 'MerchantTeamMembers/update',
        }),

        cancel() {
            for (let key of Object.keys(this.payload)) {
                this.payload[key] = '';
                this.validationErrors[key] = '';
            }

            this.$emit('cancel');
        },

        submit() {
            this.buildValidationErrors({})

            this.showLoader('Adding Team Member... Please Wait');

            this.store({...this.payload }).then(response => {
                this.hideLoader();
                this.cancel();
                this.$emit('done');
            }).catch(error => {
                this.hideLoader();
                this.buildValidationErrors(error.errors);

                if (error.status_code == 403) {
                    this.cancel();
                    this.swalError('Action Denied!', `You do not have the authorization to add new team members`);
                }
            });
        }
    },

    created() {
        //
    }
}
</script>