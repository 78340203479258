<template>
<modal
    :show="show"
    :centered="true"
    :md="true"
    :smallMaxHeight="true"
    :fixedHeight="true"
    okText="Save Product"
    @cancel="cancel"
    @ok="submit"
    okClass="btn btn-soft-primary"
>

    <h5 class="mb-4" slot="header">
        File Upload
    </h5>

    <div class="" slot="body">
        <div v-if="activeTab === 'upload'">
            <div class="mb-3">
                <p class="mb-2">
                    <a href="#">
                        <i class="mdi mdi-download me-1"></i>
                        Download our standard template
                    </a> to upload your products.
                </p>

                <p class="mb-0">
                    Don't worry if you already have your own template, you can still upload it here. 
                    You will need to map the columns to the correct fields before uploading.
                </p>
            </div>

            <div class="dropzone mb-3" id="datafile">
                <div class="fallback">
                    <input name="file" type="file" multiple />
                </div>

                <div class="dz-message needsclick">
                    <i class="h1 text-muted dripicons-cloud-upload"></i>
                    <h5 class="text-muted">Drop CSV or Excel files here or click to upload.</h5>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'matching'">
            <div class="mb-3">
                <p class="mb-0">
                    Please match the columns in your file to the fields below.
                </p>
            </div>

            <div class="d-flex mb-2" v-for="(col, index) in columns ">
                <div class="">
                    <input 
                        type="text" 
                        readonly 
                        class="form-control bg-light text-capitalize text-end"
                        :value="col" 
                        style="border-top-right-radius: 0; 
                        border-bottom-right-radius: 0; border-right: none;"/>
                </div>
                <div class="flex-fill">
                    <select class="form-control" style="border-top-left-radius: 0; border-bottom-left-radius: 0;">
                        <option>Option 1</option>
                        <option>Option 2</option>
                        <option>Option 3</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</modal>
 </template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Config from '@/config.js';

export default {
    props: ['show', 'catalog_id'],

    components: {
    },

    data() {
        return {
            dropzone: null,
            activeTab: 'matching',
            dataColumns: []
        }
    },

    computed: {
        ...mapGetters({
            payload: 'ProductsCreate/getFormValuesReqPayload',
            formValues: 'ProductsCreate/getFormValues'
        }),

        columns() {
            return Config.products.fileUploadColumns || [];
        }
    },

    methods: { 
        ...mapActions({
            store: 'Products/store',
        }),

        cancel() {
            this.$emit('cancel');
        },

        submit() {
            this.showLoader(`Saving Products... Please wait`);
            const data = {};
            this.store(data).then(async (response) => {
                this.hideLoader();
                await this.uploadImages(response);
            })
            .catch((error) => {
                this.hideLoader();
                this.swalError(
                    `Could not Save Products`, 
                    error.message || 'An error occurred while saving product'
                )
            });
        },

        onAddedFile(file) {
            console.log('file added', file);
        },

        onRemovedFile(file) {
            console.log('file removed', file);
        },

        init() {
            if (this.activeTab !== 'upload') {
                return;
            }
            
            this.dropzone = this.initDropzone('#datafile', {
                onAddedFile: this.onAddedFile,
                onRemovedFile: this.onRemovedFile
            });
        }
    },

    mounted() {
        this.init();
    }
}
</script>