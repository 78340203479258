<template>
    <div class="card">
        <div class="card-body">
            <base-tab v-model="activeTab" :options="tabOptions"/>

            <div dir="ltr">
                <div id="prod-categories" class="apex-charts pt-3"></div>
            </div>
        </div>

        <div class="card-disabled" v-if="isLoadingInsights">
            <div class="card-portlets-loader"></div>
        </div>
    </div>
</template>
<script>
import { initBarChart, initStackedAreaChart } from '@/utils/charts';

function generateDayWiseTimeSeries(baseval, count, yrange) {
    var i = 0;
    var series = [];
    while (i < count) {
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

        series.push([baseval, y]);
        baseval += 86400000;
        i++;
    }
    return series;
}

export default {
    name: 'ProductCategoriesChart',

    props: ['data', 'isLoadingInsights'],

    data() {
        return {
            activeTab: 'orders',
            intervals: [
                'Monthly', 'Weekly', 'Daily'
            ],
            params: {
                interval: 'Monthly'
            }
        }
    },

    computed: {
        tabOptions() {
            return [
                { id: 'orders', name: 'Orders'},
                { id: 'likes', name: 'Likes'},
                { id: 'dislikes', name: 'Dislikes'}
            ]
        },
        series() {
            return [
                {
                    name: 'South',
                    data: generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
                        min: 10,
                        max: 60
                    })
                },
                {
                    name: 'North',
                    data: generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
                        min: 10,
                        max: 20
                    })
                },

                {
                    name: 'Central',
                    data: generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
                        min: 10,
                        max: 15
                    })
                }
            ]
        }
    },

    methods: {
        drawChart() {
            initStackedAreaChart('#prod-categories', {
                series: this.series,
            })
        }
    },

    mounted() {
        this.drawChart()
    }
}
</script>