import { axios } from './index';

export default {
    index(params) {
        return axios.get('/catalogs/products', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    store(data) {
        return axios.post('/catalogs/products', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    update(id, data) {
        return axios.patch(`/catalogs/products/${id}`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    delete(id) {
        return axios.delete(`/catalogs/products/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    show({id, ...params}) {
        return axios.get(`/catalogs/products/${id}`, { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },
}