<template>
<div :id="showAuthLayout ? 'wrapper' : 'auth-page'">
    <template v-if="showAuthLayout">
        <side-bar/>
    
        <div class="content-page">
            <top-bar/>
    
            <div class="content">
                <div class="container-fluid">
                    <router-view/>
                </div>
            </div>
    
            <app-footer/>
        </div>
    </template>
    
    <template v-else>
        <router-view/>
    </template>

    <app-loader 
        v-if="loader.show" 
        :text="loader.text"
    />
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AppLoader from '@/components/layouts/AppLoader';
import TestingMessages from '../TestingMessages.vue';

import TopBar from '@/components/navs/TopBar.vue';
import SideBar from '@/components/navs/SideBar.vue';
import AppFooter from '@/components/navs/Footer.vue';

export default {
    components: {
        AppLoader,
        TestingMessages,
        TopBar,
        SideBar,
        AppFooter
    },

    computed: {
        ...mapGetters({
            loader: 'getLoader'
        }),

        showAuthLayout() {
            return this.$route.matched.some(record => record.meta.requiresAuth);
        }
    },

    methods: {
        ...mapActions({
        })
    },
}
</script>

<style scoped>

</style>