<template>
    <tr class="tabular-campaign">
        <td class="text-capitalize">{{ name }}</td>
        <td class="text-uppercase">{{ code }}</td>
        <td class="text-capitalize">{{ type }}</td>
        <td class="text-capitalize">{{ start_date | formattedDateTime }}</td>
        <td class="text-capitalize">{{ end_date | formattedDateTime }}</td>
        <td class="text-capitalize" :class="{'text-success': status == 'active', 'text-danger': status == 'inactive'}">
            {{ status }}
        </td>

        <td class="">
            <button
                type="button"
                @click.prevent="edit"
                class="status-btn font-xs warning">
                <i class="mdi mdi-pencil mr-1"></i> Edit
            </button>

            <button
                type="button"
                @click.prevent="view"
                class="status-btn font-xs primary">
                <i class="mdi mdi-chart-areaspline mr-1"></i> Analytics
            </button>
        </td>
    </tr>
</template>
<script>
import { get } from 'lodash'

export default {
    props: ['item'],

    computed: {
        name() {
            return get(this.item, 'name', 'N/A')
        },
        status() {
            return get(this.item, 'status', 'N/A')
        },
        code() {
            return get(this.item, 'code', 'N/A')
        },
        type() {
            return get(this.item, 'type', 'N/A')
        },
        start_date() {
            return get(this.item, 'start_date', 'N/A')
        },
        end_date() {
            return get(this.item, 'end_date', 'N/A')
        }
    },

    methods: {
        edit() {
            this.$emit('edit', this.advert)
        },

        view() {
            this.$emit('view', this.advert)
        }
    },

    created() {
        // console.log(this.advert)
    }
}
</script>