<template>
     <modal
        :show="show"
        :centered="true"
        :fixedHeight="true"
        :lg="true"
        :okText="okBtn.text"
        @cancel="cancel"
        :okIcon="okBtn.icon"
        @ok="navigate">
        <h5 class="m-0" slot="header">Create New Advert</h5>

        <div slot="body">
            <div class="row">
                <div class="col-md-12">
                    <ul class="list-group d-flex justify-content-center flex-row sticky-container mb-3">
                        <li v-for="(item, index) in tabs" 
                            :key="index" 
                            class="list-group-item btn btn-light d-flex border justify-content-between align-items-center cursor-pointer"  
                            :class="{'text-primary bg-soft-primary': activeTab === item.key}"
                            @click.prevent="activeTab = item.key">
                            <span>
                                <i class="mdi me-1" :class="item.icon"></i>
                                {{ item.name }}
                            </span>
                        </li>
                    </ul>    
                </div>
        
                <div class="col-md-12 border-start">
                    <div v-show="activeTab === 'general'">
                        <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">General</h5>
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <base-input 
                                    id="name"
                                    label="Name / Title"
                                    :required="true"
                                    v-model="payload.name"
                                    placeholder="e.g : Apple iMac"/>
                            </div>
                        </div>
    
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <base-select v-model="payload.catalog_id" label="Catalog" :required="true">
                                    <option value="">Select Catalog</option>
                                    <option v-for="(catalog, index) in catalogs" :key="index" :value="catalog.id">
                                        {{ catalog.name }}
                                    </option>
                                </base-select>
                            </div>
                            <div class="col-md-6">
                                <base-select v-model="payload.product_id" label="Product" :required="true">
                                    <option value="">Select Product</option>
                                    <option :selected="product.id === payload.product_id" v-for="(product, index) in products" :key="index" :value="product.id">
                                        {{ product.name }}
                                    </option>
                                </base-select>
                            </div>
                        </div>
    
                        <base-text-editor 
                            id="ad-desc" 
                            label="Description" 
                            :required="true" 
                            v-model="payload.description"/>
                    </div>

                    <div v-if="activeTab === 'offer'">
                        <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">Offer Info</h5>

                        <div class="row mb-3">
                            <div class="col-md-6">
                                <base-select label="Offer Type" :required="true" v-model="payload.offer.type">
                                    <option value="">Select Type</option>
                                    <option v-for="(offer, index) in offers" :key="index" :value="offer.id">
                                        {{ offer.name }}
                                    </option>
                                </base-select>
                            </div>

                            <div class="col-md-6" v-if="selectedOffer && selectedOffer.hasX">
                                <base-input 
                                    type="number"
                                    id="offer_x"
                                    label="X Value"
                                    :required="true"
                                    v-model="payload.offer.x"
                                    placeholder="e.g: 2"/>
                            </div>

                            <div class="col-md-6" v-if="selectedOffer && selectedOffer.hasY">
                                <base-input 
                                    type="number"
                                    id="offer_y"
                                    label="Y Value"
                                    :required="true"
                                    v-model="payload.offer.y"
                                    placeholder="e.g: 1"/>
                            </div>

                            <div class="col-md-6">
                                <base-input 
                                    type="date"
                                    id="expiration"
                                    label="Expiration Date"
                                    :required="true"
                                    v-model="payload.offer.expiration"
                                    placeholder="e.g: 1"/>
                            </div>
                        </div>
                    </div>

                    <div v-if="activeTab === 'sale'">
                        <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">Sale Terms</h5>

                        <div class="row mb-3">
                            <div class="col-md-6">
                                <base-input 
                                    type="number"
                                    id="price"
                                    label="Price"
                                    :required="true"
                                    v-model="payload.sale_terms.price"
                                    placeholder="e.g: 99.99"/>
                            </div>

                            <div class="col-md-6">
                                <base-input 
                                    type="text"
                                    id="warranty"
                                    label="Warranty"
                                    :required="true"
                                    v-model="payload.sale_terms.warranty.duration"
                                    placeholder="e.g: 1 year"/>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-md-6">
                                <base-input 
                                    type="number"
                                    id="delivery"
                                    label="Delivery Terms"
                                    desc="Days to deliver after order"
                                    :required="true"
                                    v-model="payload.sale_terms.delivery_terms.days_after_order"
                                    placeholder="e.g: 2"/>
                            </div>

                            <div class="col-md-6">
                                <base-input 
                                    type="number"
                                    id="return"
                                    label="Return Policy"
                                    desc="Days to return after delivery"
                                    :required="true"
                                    v-model="payload.sale_terms.return_policy"
                                    placeholder="e.g: 7"/>
                            </div>
                        </div>

                        <base-text-editor 
                            label="Additional Delivery Terms (e.g Signature required)"
                            id="additional-del-terms" 
                            v-model="payload.sale_terms.delivery_terms.additional_terms"/>
                    </div>

                    <div v-show="activeTab === 'adcopy'">
                        <ad-copy 
                            v-model="dropzone" 
                            :mediaFiles="advert ? advert.media_files : []"
                            :deletedFiles="payload.deleted_media_files"
                            @remove="id => payload.deleted_media_files.push(id)"/>
                    </div>

                    <div v-if="activeTab === 'claim'">
                        <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">Promotional Claim</h5>

                        <empty title="Please selected a product first" v-if="!selectedProduct"/>

                        <div class="d-flex flex-column" v-if="selectedProduct">
                            <div 
                                class="d-flex align-items-center gap-1 btn btn-block text-start border p-2 mb-2" 
                                v-for="(claim, index) in claims" :key="index"
                                @click="selectClaim(claim)">
                                <i class="mdi fs-4" 
                                    :class="{
                                        'mdi-checkbox-marked text-primary': claim === payload.promotional_claim,
                                        'mdi-checkbox-blank-outline text-muted': claim !== payload.promotional_claim,
                                    }"></i>
                                {{ claim }}
                            </div>

                            <div 
                                class="d-flex align-items-center gap-1 btn btn-block text-start border p-2 mb-2"
                                @click="selectClaim('')"
                                v-if="claims.length">
                                <i class="mdi fs-4" 
                                    :class="{
                                        'mdi-checkbox-marked text-primary': showCustomClaim,
                                        'mdi-checkbox-blank-outline text-muted': !showCustomClaim,
                                    }"></i>
                                Enter Promotional Claim
                            </div>

                            <base-input 
                                v-if="showCustomClaim"
                                v-model="payload.promotional_claim"
                                label="Promotional Claim" 
                                :required="true" 
                                placeholder="Default promotional claim"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </modal>
</template>
<script>
import { debounce, get } from 'lodash';
import Config from '@/config';
import AdCopy from './AdCopy.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['show', 'advert'],

    components: {
        AdCopy
    },

    data() {
        return {
            showCustomClaim: false,
            dropzone: null,

            payload: {
                name: '',
                catalog_id: '',
                product_id: '',
                description: '',
                offer: { type: '', x: '', y: '', expiration: '' },
                promotional_claim: '',
                sale_terms: {
                    price: '',
                    return_policy: '',
                    delivery_terms: {
                        days_after_order: '',
                        additional_terms: ''
                    },
                    warranty: {
                        type: '',
                        unit: '',
                        duration: ''
                    },
                },
                deleted_media_files: []
            },

            activeTab: 'general',

            tabs: [
                { key: 'general', name: 'General', icon: 'mdi-information-outline' },
                { key: 'offer', name: 'Offer', icon: 'mdi-offer' },
                { key: 'sale', name: 'Sale Terms', icon: 'mdi-sale' },
                { key: 'adcopy', name: 'Ad Copy', icon: 'mdi-file-image-outline' },
                { key: 'claim', name: 'Promotional Claim', icon: 'mdi-star-box-multiple-outline' },
            ]
        }
    },

    computed: {
        ...mapGetters({
            catalogs: 'Catalogs/getAll',
            products: 'Products/getAll',
        }),

        claims() {
            return this.selectedProduct ? this.selectedProduct.claims : [];
        },

        offers() {
            return Config.adverts.offers;
        },

        selectedOffer() {
            return this.offers.find(offer => offer.id === this.payload.offer.type);
        },

        selectedProduct() {
            return this.products.find(product => product.id === this.payload.product_id);
        },

        isLastTab() {
            return this.tabs[this.tabs.length - 1].key === this.activeTab;
        },

        isUpdate() {
            return !!this.advert;
        },

        okBtn() {
            if (this.isLastTab || this.isUpdate) return {
                text: this.advert ? 'Save Changes' : 'Submit',
                icon: 'mdi mdi-check'
            };

            return {
                text: 'Next',
                icon: 'mdi mdi-arrow-right'
            };
        },
    },

    watch: {
        'payload.catalog_id'() {
            this.optionallyLoadProducts();
        },

        selectedProduct() {
            if (this.selectedProduct && this.claims.length === 0) {
                this.selectClaim('');
            }
        },

        advert() {
            this.resetPayload();
        }
    },

    methods: {
        ...mapActions({
            getProducts: 'Products/index',
            store: 'Adverts/store',
            update: 'Adverts/update'
        }),

        optionallyLoadProducts() {
            if (!this.payload.catalog_id) return;

            this.getProducts({
                catalog_id: this.payload.catalog_id,
                paginate: 1,
                slim: 1
            });
        },

        navigate() {
            if (this.isLastTab || this.isUpdate) return this.submit();

            const index = this.tabs.findIndex(tab => tab.key === this.activeTab);
            this.activeTab = this.tabs[index + 1].key;
        },

        selectClaim(claim) {
            this.payload.promotional_claim = claim;
            this.showCustomClaim = claim === '';
        },

        resetPayload() {
            this.payload.id = get(this.advert, 'id', '');
            this.payload.name = get(this.advert, 'name', '');
            this.payload.catalog_id = get(this.advert, 'catalog_id', '');
            this.payload.product_id = get(this.advert, 'product_id', '');
            this.payload.description = get(this.advert, 'description', '');
            this.payload.offer.type = get(this.advert, 'offer.type', '');
            this.payload.offer.x = get(this.advert, 'offer.x', '');
            this.payload.offer.y = get(this.advert, 'offer.y', '');
            this.payload.offer.expiration = get(this.advert, 'offer.expiration', '');
            this.payload.promotional_claim = get(this.advert, 'promotional_claim', '');
            this.payload.sale_terms.price = get(this.advert, 'sale_terms.price', '');
            this.payload.sale_terms.return_policy = get(this.advert, 'sale_terms.return_policy', '');
            this.payload.sale_terms.delivery_terms.days_after_order = get(this.advert, 'sale_terms.delivery_terms.days_after_order', '');
            this.payload.sale_terms.delivery_terms.additional_terms = get(this.advert, 'sale_terms.delivery_terms.additional_terms', '');
            this.payload.sale_terms.warranty.duration = get(this.advert, 'sale_terms.warranty.duration', '');
            this.payload.deleted_media_files = [];
            console.log(this.payload);
            this.activeTab = 'general';
        },

        cancel() {
            this.resetPayload();
            this.$emit('cancel');
        },

        getPayload() {
            const data = {...this.payload}
            if (this.dropzone && this.dropzone.files.length > 0) {
                data.media_files = this.dropzone.files.map(file => {
                    return {
                        id: file.upload.uuid,
                        mime: file.type, 
                    }
                });
            }
            if  (this.isUpdate) {
                data.action = 'update-all';
            }
            return data;
        },

        submit() {
            this.showLoader(`Saving Advert... Please wait`);
            const data = this.getPayload();
            console.log(data);
            const method = this.isUpdate ? this.update : this.store;

            method(data).then(async (response) => {
                this.hideLoader();
                await this.uploadMediaFiles(response);
            })
            .catch(() => {
                this.hideLoader();
            });
        },

        async uploadMediaFiles(response) {
            console.log({response});
            const presignedUrls = response.presigned_urls;
            const listing = response.listing;

            const dropzone = this.dropzone;

            console.log({presignedUrls, listing, dropzone});
            
            if (presignedUrls.length === 0) {
                this.hideLoader();
                this.$emit('refresh');
                this.cancel();
                return;
            }

            this.showLoader('Uploading files... Please wait');

            this.processDropzoneQueue(dropzone, {
                presignedUrls: presignedUrls,
                onCompleted: async () => {
                    await this.update({...listing, action:'update-files'});
                    this.$emit('refresh')
                    this.hideLoader();
                    this.cancel();
                }
            })
        }
    },

    created() {
        this.optionallyLoadProducts();
    }
}
</script>