export default {
    routerMode: process.env.VUE_APP_ROUTER_MODE,
    baseURL: process.env.VUE_APP_BASE_API_URL,

    //firebase config
    firebase: {
        vapid: process.env.VUE_APP_FIREBASE_VAPID,
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_FIREBASE_APP_ID,
        measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
    },

    catalogs: {
        types: [
            {
                id: 'product',
                name: 'Products'
            },
            // {
            //     id: 'service',
            //     name: 'Services'
            // },
            // {
            //     id: 'event',
            //     name: 'Events'
            // },
            // {
            //     id: 'automobile',
            //     name: 'Automobiles'
            // },
            // {
            //     id: 'real_estate',
            //     name: 'Real Estate'
            // }
        ]
    },

    products: {
        availabilities: [
            { id: 'in-stock', name: 'In Stock' },
            { id: 'preorder', name: 'Available for Preorder' },
            { id: 'out-of-stock', name: 'Out of Stock' },
        ],
        globalIdTypes: [
            { id: 'UPC', name: 'UPC' },
            { id: 'EAN', name: 'EAN' },
            { id: 'ISBN', name: 'ISBN' },
            { id: 'GTIN', name: 'GTIN' },
            { id: 'MPN', name: 'MPN' },
            { id: 'SKU', name: 'SKU' },
        ],
        fileUploadColumns: [
            'name',
            'global id type',
            'global id value',
            'sku',
            'price',
            'status',
            'condition',
            'description',
            'objective',
        ],
    },

    currencies: [
        { id: 'USD', name: 'USD' },
        { id: 'EUR', name: 'EUR' },
        { id: 'GBP', name: 'GBP' },
    ],

    coupons: {
        statuses: ['active', 'inactive', 'expired'],
    },

    adverts: {
        offers: [
            { id: 'fixed-price', name: 'Fixed Price' },
            { id: 'subscription', name: 'Subscription' },
            { id: 'buy-x-get-y', name: 'Buy X Get Y', hasX: true, hasY: true },
            { id: 'buy-x-get-gift', name: 'Buy X Get Gift', hasX: true },
            { id: 'x-off', name: 'X Off', hasX: true },
            { id: 'x-percent-off', name: 'X Percent Off', hasX: true },
            { id: 'buy-now-pay-later', name: 'Buy Now Pay Later' },
        ]
    },

    audiences: {
        psychographics: {
            age: {
                lower: [18, 25, 35, 45, 55, 65],
                upper: [24, 34, 44, 54, 64]
            },
            genders: ['any', 'male', 'female', 'other']
        }
    }
}