<template>
    <div class="card product-box">
        <div class="card-body">
            <div class="product-action d-flex gap-1">
                <router-link :to="detailsRoute" 
                    :href="`/merchant/catalogs/products/${product.id}`"
                    class="btn btn-secondary btn-xs waves-effect waves-light">
                    <i class="mdi mdi-eye"></i>
                </router-link>

                <a href="javascript: void(0);" @click.prevent="$emit('edit', product)" class="btn btn-success btn-xs waves-effect waves-light">
                    <i class="mdi mdi-pencil"></i>
                </a>
                <a href="javascript: void(0);" @click.prevent="deleteProduct" class="btn btn-danger btn-xs waves-effect waves-light">
                    <i class="mdi mdi-close"></i>
                </a>
            </div>

            <div class="bg-light d-flex gap-1 p-1 overflow-scroll">
                <img 
                    v-for="(image, index) in images"
                    :src="image.url" 
                    alt="product-pic" 
                    :class="{ 'p-3': image.padded }"
                    class="img-fluid img-thumbnail avatar-xl"
                />
            </div>

            <div class="product-info">
                <div class="row align-items-center">
                    <div class="col-8">
                        <h5 class="font-16 mt-0 sp-line-1 text-truncate">
                            <router-link 
                                :to="detailsRoute" 
                                :href="`/merchant/catalogs/products/${product.id}`" 
                                class="text-dark">
                                {{ product.name }}
                            </router-link> 
                        </h5>
                        <div class="text-warning mb-2 font-13">
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                        </div>
                        <h5 class="m-0"> 
                            <span class="text-muted text-capitalize">{{ product.status }}</span>
                        </h5>
                    </div>
                    <div class="col-4">
                        <div class="product-price-tag p-1 font-sm">
                            {{ product.currency }} {{ product.price }}
                        </div>
                    </div>
                </div> <!-- end row -->
            </div> <!-- end product info-->
        </div>
    </div>
</template>
<script>
import { get } from 'lodash';
import { mapActions } from 'vuex';
export default {
    props: {
        product: {
            type: Object,
            required: true
        }
    },

    computed: {
        detailsRoute() {
            return {
                name: 'catalogs.products.view', 
                params: { product_id: this.product ? this.product.id : '' }
            };
        },

        images() {
            let images = get(this.product, 'media_files', [])

            if (images.length) {
                return images;
            }

            return get(this.product, 'temp_images', []).map(image => {
                return {
                    url: '/img/loader.gif',
                    padded: true
                }
            });
        }
    },

    methods: {
        ...mapActions({
            deleteProd: 'Products/delete'
        }),

        async deleteProduct() {
            const yes = await this.confirmDelete({
                title: 'Delete Product',
                message: 'Are you sure you want to delete this product?',
            });

            if (yes) {
                this.deleteProd(this.product.id);
            }
        }
    },

    // mounted() {
    //     console.log(this.product);
    // }
}
</script>