import { get } from 'lodash';

const HEIGHT = 300;

export const initBarChart = (selector, config = {}) => {
    const {
        categories, 
        yAxisTitle, 
        formatTooltip, 
        ...rest
    } = config;

    const options = {
        colors: ['#6658dd', '#1abc9c', '#f672a7'],
        chart: {
            height: HEIGHT,
            type: 'bar',
            toolbar: {
                show: true
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                endingShape: 'rounded',
                columnWidth: '55%',
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 4,
            colors: ['transparent']
        },
        xaxis: {
            categories: categories,
        },
        legend: {
            offsetY: 5,
        },
        yaxis: {
            title: {
                text: yAxisTitle || 'Y-Axis',
            }
        },
        fill: {
            opacity: 1
        },
        grid: {
            row: {
                colors: ['transparent', 'transparent'],
                opacity: 0.2
            },
            borderColor: '#f1f3fa',
            padding: {
                bottom: 10
            }
        },
        tooltip: {
            y: {
                formatter: get(formatTooltip, 'x', function (val) {
                    return 'X: ' + val
                })
            },
            x: {
                formatter: get(formatTooltip, 'y', function (val) {
                    return 'Y: ' + val
                })
            }
        },
        ...rest,
    }

    const chart = new ApexCharts(
        document.querySelector(selector),
        options
    );
    chart.render();
    return chart;
};

export const initStackedAreaChart = (selector, config = {}) => {
    var options = {
        chart: {
            height: HEIGHT,
            type: 'area',
            stacked: true,
            events: {
                selection: function (chart, e) {
                    console.log(new Date(e.xaxis.min))
                }
            },

        },
        colors: ['#6658dd', '#f7b84b', '#CED4DC'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [2],
            curve: 'smooth'
        },

        fill: {
            type: 'gradient',
            gradient: {
                opacityFrom: 0.6,
                opacityTo: 0.8,
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left'
        },
        xaxis: {
            type: 'datetime'
        },
        ...config,
    }

    const chart = new ApexCharts(
        document.querySelector(selector),
        options
    );

    chart.render();

    return chart;
};