<template>
<content-wrapper>
    <page-title title="Campaigns">
        <button @click.prevent="createAdvert" class="btn btn-soft-success">
            <i class="mdi mdi-plus-circle"></i> Create Campaign
        </button>
    </page-title>

    <div class="campaign-filters mb-3">
        <div class="row align-items-center">
            <div class="col-md-8 mb-3">
                <div class="btn-group">
                    <button
                        v-for="(status, index) in statuses"
                        @click.prevent="setStatus(status.value)"
                        :class="{'text-primary': params.status == status.value}"
                        class="btn btn-secondary-success bg-white rounded-0"
                        type="button">
                        <i class="mdi" :class="status.icon"></i> {{ status.name }}
                    </button>
                </div>
            </div>
            <div class="col-md-4 d-flex mb-3">
                <form class="search-bar w-100">
                    <div class="position-relative">
                        <input v-model="params.keyword" 
                            type="text" class="form-control form-control-white" 
                            placeholder="Search by keyword...">
                        <span class="mdi mdi-magnify"></span>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4 col-12" v-for="(campaign, index) in all" :key="index">
            <campaign-item 
                :campaign="campaign" 
                @edit="edit(campaign)" 
                @refresh="loadData({clearState: true})"/>
        </div>
    </div>

    <empty v-if="!all.length" title="No Ad found"/>

    <create-campaign 
        :show="showCampaign" 
        :advert="editableCampaign"
        @cancel="edit(null)" 
        @refresh="loadData({clearState: true})"/>

</content-wrapper>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import CampaignItem from '@/views/merchant/campaigns/partials/CampaignItem.vue';
import CreateCampaign from '@/views/merchant/campaigns/partials/CreateCampaign.vue';

export default {
    components: {
        CampaignItem,
        CreateCampaign,
    },

    data() {
        return {
            showCampaign: false,
            editableCampaign: null,
            statuses: [
                {name: 'All', value: 'all', icon: 'mdi-format-list-bulleted'},
                {name: 'Launched', value: 'active', icon: 'mdi-radiobox-marked'},
                {name: 'Paused', value: 'paused', icon: 'mdi-stop-circle-outline'},
                {name: 'Draft', value: 'draft', icon: 'mdi-paperclip'},
                {name: 'Archived', value: 'archived', icon: 'mdi-archive'},
                {name: 'Trashed', value: 'trashed', icon: 'mdi-trash-can'},
            ],
            params: {
                status: 'all',
                keyword: '',
            }
        }
    },

    computed: {
        ...mapGetters({
            all: 'Campaigns/getAll',
            pageDetails: 'Campaigns/getPageDetails'
        })
    },

    watch: {
    },

    methods:{
        ...mapActions({
            index: 'Campaigns/index',
            store: 'Campaigns/store',
        }),

        setStatus(value) {
            this.params.status = value;
            this.performFiltering();
        },

        performFiltering() {
            this.loadData({clearState: true});
        },

        edit(advert) {
            if (!advert) {
                this.editableCampaign = null;
                this.showCampaign = false;
                return;
            }

            this.editableCampaign = advert;
            this.showCampaign = true;
        },

        view(advert) {
            this.viewableAdvert = advert;
            this.showViewAdvertModal = true;
        },

        loadData(query) {
            let params = {
                paginate: true,
                per_page: 20,
                ...query,
                ...this.params,
                keyword: this.keyword
            }

            this.loading = true;

            this.showLoader('Loading ads... Please wait');

            this.index(params)
                .then(response => {
                    this.hideLoader();
                    console.log(response);
                })
                .catch(error => {
                    this.loading = false;
                    this.hideLoader();
                })
        },

        createAdvert() {
            this.showCampaign = true;
        },

        showPartialsModal(key) {
            this.showModals[key] = true;
        },
    },

    created() {
        this.loadData({clearState: true});
    },
}
</script>