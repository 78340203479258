<template>
    <div class="page-title-box d-flex align-items-end mb-3 mt-3">
        <h4 class="h2 m-0" v-html="title"></h4>

        <div class="d-flex justify-content-end flex-fill">
            <slot/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-title',

    props: {
        title: String,
        desc: String,
        className: {
            type: String,
            default: 'mb-3'
        }
    },

    computed: {
        routeName() {
            return this.$route.name;
        }
    },
}
</script>

<style>

</style>
