import Vue from 'vue';
import routes from './routes';
import Store from '@/store';
import VueRouter from 'vue-router';
import config from '@/config';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: config.routerMode,
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!Store.getters['Authentications/isLoggedIn']) {
      return next({name: 'login', query: {redirect: to.fullPath}});
    } else {
      const user = Store.getters['Authentications/getUser'];

      return next();
    }
  } else {
    return next();
  }
});

router.afterEach((to, from) => {
  $('title').text(`Woonmi ${to.meta && to.meta.title ? ' | ' + to.meta.title : ''}`);

  Store.dispatch('closeSidebar');
});

export default router
