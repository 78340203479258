<template>
    <content-wrapper>
        <page-title>Dashboard</page-title>
    </content-wrapper>
</template>
<script>
import { mapActions } from 'vuex'
import Partials from './partials'

export default {
    components: {
        ...Partials
    },

    data() {
        return {
            loading_pinned: true,
            pinnedIntent: null
        }
    },

    methods: {
        ...mapActions({
            getPinnedIntent: 'Intents/getPinned'
        }),

        loadPinnedIntent() {
            this.loading_pinned = true;

            this.getPinnedIntent()
                .then(response => {
                    this.loading_pinned = false;
                    this.pinnedIntent = response;
                })
                .catch(error => {
                    this.loading_pinned = false;
                })
        }
    },

    created() {
        // this.loadPinnedIntent()
    }
}
</script>
