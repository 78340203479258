<template>
    <content-wrapper>
        <page-title title="Settings">
            <button @click.prevent="logout" class="btn btn-secondary font-small elevation-2 pt-2 pb-2 ml-2">
                <i class="mdi mdi mdi-logout"></i> Logout
            </button>
        </page-title>

        <responsive-width>
            <settings v-model="activeTab"/>

            <div class="row" v-if="active == 'ed_pr'">
                <div class="col-md-12">
                    <edit-profile @cancel="activeTab = null"/>
                </div>
            </div>

            <div class="row" v-if="active == 'ch_pa'">
                <div class="col-md-6">
                    <change-password @cancel="activeTab = null"/>
                </div>
            </div>

            <div class="row" v-if="active == 'ref'">
                <div class="col-md-8">
                    <referrals @cancel="activeTab = null"/>
                </div>
            </div>

            <div class="row" v-if="active == 'pr_se'">
                <div class="col-md-6">
                    <privacy @cancel="activeTab = null"/>
                </div>
            </div>

            <div class="row" v-if="active == '2fa'">
                <div class="col-md-12">
                    <two-factor-authentication @cancel="activeTab = null"/>
                </div>
            </div>

            <div class="row" v-if="active == 'tutorials'">
                <div class="col-12">
                    <tutorials @cancel="activeTab = null"/>
                </div>
            </div>

            <div class="row justify-content-center" v-if="active == 'about'">
                <div class="col-12">
                    <about @cancel="activeTab = null"/>
                </div>
            </div>

            <div class="row" v-if="active == 'support'">
                <div class="col-12">
                    <support @cancel="activeTab = null"/>
                </div>
            </div>
        </responsive-width>
    </content-wrapper>
</template>
<script>
import {mapActions} from 'vuex';
import About from './partials/About';
import Privacy from './partials/Privacy';
import Referrals from './partials/Referrals';
import Settings from './partials/Settings.vue';
import Support from './partials/support/Index.vue';
import ChangePassword from './partials/ChangePassword';
import TwoFactorAuthentication from './partials/2FA.vue';

export default {
    components: {
        About,
        Support,
        Privacy,
        Settings,
        Referrals,
        ChangePassword,
        TwoFactorAuthentication
    },

    data() {
        return {
            activeTab: null
        }
    },

    computed: {
        active() {
            if (this.activeTab) {
                return this.activeTab.id;
            }

            return null
        }
    },

    methods: {
        ...mapActions({
            signOut: 'Authentications/logout'
        }),

        logout() {
            this.swalConfirm('Are you sure?', `Please confirm you wish to logout`, `YES, Logout`, `No, I'll stay`)
            .then(result => {
                if (result.value) {
                    this.signOut()
                    .then(response => null)
                    .catch(error => null)
                } else {
                    this.$emit('input')
                }
            })
        },
    }
}
</script>