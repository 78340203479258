<template>
    <div class="card project-box mb-3">
        <div class="card-body">
            <div class="dropdown float-end">
                <a href="#" class="dropdown-toggle card-drop arrow-none" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal m-0 text-muted h3"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" href="#" @click.prevent="renameAud">
                        <i class="mdi mdi-circle-edit-outline"></i>
                        Rename
                    </a>
                    <a class="dropdown-item" href="#" @click.prevent="deleteAud">
                        <i class="mdi mdi-trash-can-outline"></i>
                        Delete
                    </a>
                </div>
            </div> 
            
            <h4 class="mt-0">
                {{ audience.name }}
            </h4>

            <p class="text-muted text-uppercase mb-1">
                <i class="mdi mdi-account-circle"></i> 
                <small>{{ audience.created_by.username }}</small>
            </p>

            <hr class="my-1">

            <label class="text-muted fs-6 mb-1">Demographics:</label>
            <div class="d-flex flex-wrap gap-1">
                <div v-for="(country, index) in audience.countries" class="badge text-primary bg-soft-secondary p-1 text-capitalize">
                    <span class="me-1">{{ country.emoji }}</span>
                    <span>{{ country.name }}</span>
                </div>
                
                <div v-if="audience.countries.length === 0" class="badge text-primary bg-soft-secondary p-1 text-capitalize">
                    All Countries
                </div>

                <div v-for="(state, index) in audience.states" class="badge text-primary bg-soft-secondary p-1 text-capitalize">
                    <span class="me-1">{{ state.emoji }}</span>
                    <span>{{ state.name }}</span>
                </div>
            </div>

            <hr class="my-1">

            <label class="text-muted fs-6 mb-1">Age Group & Gender:</label>
            <div class="d-flex gap-1 flex-wrap">
                <div class="badge text-primary bg-soft-secondary p-1 text-capitalize">
                    {{ audience.age.range_lower }} - {{ audience.age.range_upper }} years
                </div>
                <div class="badge text-primary bg-soft-secondary p-1 text-capitalize">
                    {{ ['any', 'other'].includes(audience.gender) ? `${audience.gender} Gender` : audience.gender }}
                </div>
                <div class="badge bg-soft-success text-success p-1 text-capitalize" v-if="audience.number_in_use">
                    In Use By {{ audience.number_in_use }} Campaigns
                </div>
            </div>
            
            <hr class="my-1">

            <label class="text-muted fs-6 mb-1">Interested In:</label>
            <div class="d-flex flex-wrap gap-1">
                <div v-for="(interest, index) in audience.interests" class="badge text-primary bg-soft-secondary p-1 text-capitalize">
                    <span>{{ interest.name }}</span>
                </div>
                
                <div v-if="audience.interests.length === 0" class="badge text-primary bg-soft-secondary p-1 text-capitalize">
                    Any Interest
                </div>
            </div>

            <hr class="my-1">

            <label class="text-muted fs-6 mb-1">Ordered or Liked Any of these categories:</label>
            <div class="d-flex flex-wrap gap-1">
                <div v-for="(category, index) in audience.categories" class="badge text-primary bg-soft-secondary p-1 text-capitalize">
                    <span>{{ category.name }}</span>
                </div>
                
                <div v-if="audience.categories.length === 0" class="badge text-primary bg-soft-secondary p-1 text-capitalize">
                    Any Interest
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        audience: {
            type: Object,
            required: true
        }
    },

    methods: {
        deleteAud() {},
        renameAud() {}
    }
}
</script>