<template>
    <div class="">
        <label for="ad-desc" class="form-label">
            {{ label }} 
            <span class="text-danger">*</span>
        </label>

        <div :id="id" :style="{'min-height': minHeight}"></div>
    </div>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
        },
        value: {
            type: String,
        },
        id: {
            type: String,
            required: true,
        },
        minHeight: {
            type: String,
            default: '100px',
        },
    },

    data() {
        return {
            editor: null,
        }
    },

    watch: {
        value(value) {
            if (this.editor && this.editor.root.innerHTML !== value) {
                // console.log('Value changed from parent', value);
                this.editor.pasteHTML(value);
            }
        }
    },

    mounted() {
        this.editor = this.initEditor(`#${this.id}`, {
            value: this.value,
            onChange: (value) => {
                if (this.value !== value) {
                    // console.log('Value changed from editor', value);
                    this.$emit('input', value);
                }
            },
        });
    },
}
</script>