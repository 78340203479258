<template>
    <div class="app-menu">  
        <!-- Brand Logo -->
        <div class="logo-box d-flex" v-if="logoUrl">
            <!-- Brand Logo Light -->
            <a href="#" @click.prevent="" class="logo-light">
                <img :src="logoUrl" alt="logo" class="logo-lg">
                <img :src="logoUrl" alt="small logo" class="logo-sm">
            </a>

            <!-- Brand Logo Dark -->
            <a href="#" @click.prevent="" class="logo-dark">
                <img :src="logoUrl" alt="dark logo" class="logo-lg">
                <img :src="logoUrl" alt="small logo" class="logo-sm">
            </a>

            <h5 class="m-0 ms-1 text-uppercase text-truncate">{{ merchantName }}</h5>
        </div>

        <!-- menu-left -->
        <div class="scrollbar">

            <!-- User box -->
            <div class="user-box text-center">
                <img src="/theme/images/users/user-1.jpg" alt="user-img" title="Mat Helme" class="rounded-circle avatar-md">
                <div class="dropdown">
                    <a href="javascript: void(0);" class="dropdown-toggle h5 mb-1 d-block">
                        {{ fullName }}
                    </a>
                </div>
                <p class="text-muted mb-0">
                    @{{ authUser.username }}
                </p>
            </div>

            <!--- Menu -->
            <ul class="menu">
                <!-- <li class="menu-item" :class="{'menuitem-active': isCatalogs}">
                    <router-link :to="{name: 'catalogs'}" href="#" class="menu-link">
                        <span class="menu-icon me-1">
                            <i class="material-symbols-outlined">inventory</i>
                        </span>
                        <span class="menu-text"> Catalogs </span>
                        <span class="badge bg-pink ms-auto">Hot</span>
                    </router-link>
                </li> -->

                <li class="menu-item" :class="{'menuitem-active': isCatalogs}">
                    <a href="#catalogs" data-bs-toggle="collapse" class="menu-link">
                        <span class="menu-icon me-1">
                            <i class="material-symbols-outlined">inventory</i>
                        </span>
                        <span class="menu-text"> Catalogs </span>
                        <span class="menu-arrow"></span>
                    </a>
                    <div class="collapse" id="catalogs">
                        <ul class="sub-menu">
                            <li class="menu-item active">
                                <router-link :to="{name: 'catalogs'}" href="#" class="menu-link" active-class="text-primary">
                                    <span class="menu-text">All Catalogs</span>
                                </router-link>
                            </li>
                            <li class="menu-item">
                                <router-link :to="{name: 'catalogs.products'}" href="#" class="menu-link" active-class="text-primary">
                                    <span class="menu-text">Products</span>
                                </router-link>
                            </li>
                            <li class="menu-item">
                                <a href="#" class="menu-link">
                                    <span class="menu-text">Services</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                
                <li class="menu-item" :class="{'menuitem-active': isAds}">
                    <router-link :to="{name: 'listings.list'}" href="#" class="menu-link">
                        <span class="menu-icon me-1">
                            <i class="material-symbols-outlined">ads_click</i>
                        </span>
                        <span class="menu-text"> Ads </span>
                    </router-link>
                </li>

                <li class="menu-item" :class="{'menuitem-active': isAudience}">
                    <router-link :to="{name: 'audiences'}" href="#" class="menu-link">
                        <span class="menu-icon me-1">
                            <i class="material-symbols-outlined">groups</i>
                        </span>
                        <span class="menu-text"> Audience </span>
                    </router-link>
                </li>

                <li class="menu-item" :class="{'menuitem-active': isCampaigns}">
                    <router-link :to="{name: 'campaigns.list'}" href="#" class="menu-link">
                        <span class="menu-icon me-1">
                            <i class="material-symbols-outlined">campaign</i>
                        </span>
                        <span class="menu-text"> Campaigns </span>
                    </router-link>
                </li>

                <li class="menu-item" :class="{'menuitem-active': isOrders}">
                    <router-link :to="{name: 'orders.list'}" href="#" class="menu-link">
                        <span class="menu-icon me-1">
                            <i class="material-symbols-outlined">shopping_cart_checkout</i>
                        </span>
                        <span class="menu-text"> Orders </span>
                    </router-link>
                </li>

                <li class="menu-item" :class="{'menuitem-active': isFiles}">
                    <router-link :to="{name: 'files.library'}" href="#" class="menu-link">
                        <span class="menu-icon me-1">
                            <i class="material-symbols-outlined">drive_folder_upload</i>
                        </span>
                        <span class="menu-text"> Files Library </span>
                    </router-link>
                </li>

                <hr class="m-0"/>

                <li class="menu-item" :class="{'menuitem-active': isMembers}">
                    <router-link :to="{name: 'members.list'}" href="#" class="menu-link">
                        <span class="menu-icon me-1">
                            <i class="material-symbols-outlined">groups</i>
                        </span>
                        <span class="menu-text"> Team Members </span>
                    </router-link>
                </li>
            </ul>

            <!--- End Menu -->
            <div class="clearfix"></div>
        </div>
</div>
</template>
<script>
import { get } from 'lodash';

export default {
    computed: {
        isCatalogs() {
            return this.$route.name.includes('catalogs.');
        },
        isFiles() {
            return this.$route.name.includes('files.');
        },
        isMembers() {
            return this.$route.name.includes('members.');
        },
        isCampaigns() {
            return this.$route.name.includes('campaigns.');
        },
        isAds() {
            return this.$route.name.includes('listings.');
        },
        isAudience() {
            return this.$route.name.includes('audiences.');
        },
        isOrders() {
            return this.$route.name.includes('orders.');
        },

        logoUrl() {
            if (this.authUser.active_merchant) {
                return this.authUser.active_merchant.logo || this.authUser.active_merchant.logo_url;
            }

            return null
        },
        fullName() {
            if (this.authUser.fullname) {
                return this.authUser.fullname;
            }

            return this.authUser.first_name + ' ' + this.authUser.last_name;
        },
        merchantName() {
            return get(this.authUser, 'active_merchant.name', 'Merchant Name');
        }
    },

    created() {
        // console.log(this.authUser)
    }
}
</script>