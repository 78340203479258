<template>
    <div @click.prevent="$emit('selectItem', item)" 
        class="btn d-flex align-items-center p-2 rounded mb-1 cursor-pointer"
        :class="{'bg-soft-success': active, 'btn-light': !active}">
        <div class="rounded-circle avatar-sm me-2" 
            v-if="activeTab === 'colors'"
            :style="{background: item.hex}">
        </div>

        <template v-else>
            <img :src="iconUrl" 
                class="rounded-circle avatar-sm me-2 object-fit-cover bg-white" 
                alt="" v-if="iconUrl">

            <div class="rounded-circle avatar-sm me-2 bg-white d-flex justify-content-center align-items-center" v-else>
                <i class="mdi m-0 h3" :class="icon"></i>
            </div>
        </template>


        <div class="flex-grow-1 text-start">
            <h5 class="font-size-14 m-0 text-truncate text-capitalize">{{ item.name }}</h5>
            <p class="m-0 text-muted text-truncate text-capitalize">{{ item.subtitle }}</p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        activeTab: {
            type: String,
            required: true
        },
        active: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        iconUrl() {
            if (this.item.icon_url) {
                return this.item.icon_url;
            } else if (this.item.image_url) {
                return this.item.image_url;
            } else if (this.item.image) {
                return this.item.image;
            } else {
                return null;
            }
        },

        icon() {
            if (this.activeTab === 'sizes') {
                return 'mdi-ruler';
            } else if (this.activeTab === 'brands') {
                return 'mdi-tag';
            } else if (this.activeTab === 'materials') {
                return 'mdi-texture';
            } else if (this.activeTab === 'colors') {
                return 'mdi-palette';
            } else {
                return 'mdi-cube';
            }
        }
    }
}
</script>