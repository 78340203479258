<template>
    <modal
    :show="show"
    :centered="true"
    :md="true"
    :showFooter="true"
    :maxHeight="true"
    okText="Submit"
    @ok="submit"
    @cancel="cancel">
        <h5 class="m-0 text-capitalize" slot="header"></h5>
        <div slot="body" class="">
            <div class="alert alert-warning">
                Note that this audience will be created with the current filters applied from 
                countries, age range, interests and product categories.
            </div>
            <base-input 
                name="name" 
                v-model="name"
                label="Give Audience a name" 
                placeholder="Enter a name for this audience"/>
        </div>
    </modal>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    props: ['show', 'filters'],

    data() {
        return {
            name: ''
        }
    },

    methods: {
        ...mapActions({
            store: 'Audience/store'
        }),

        reset() {
            this.name = '';
        },
        
        cancel() {
            this.reset();
            this.$emit('cancel');
        },

        submit() {
            if (!this.name) {
                return this.swalError('Name is required', 'Please enter a name for this audience');
            }

            this.showLoader('Creating Audience... Please wait');

            const data = {name: this.name, ...this.filters };

            this.store(data).then(() => {
                this.hideLoader();
                this.cancel();
            })
            .catch((error) => {
                console.log(error);
                this.hideLoader();
                this.swalError(
                    'Error creating audience', 
                    error.message || 'An error occurred while creating audience',
                    false
                );
            });
        },
    }
}
</script>