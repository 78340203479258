import Service from '@/services/media_files';

const state = {
    all: [],

    pageDetails: {
        to: 0,
        from: 0,
        total: 0,
        perPage: 0,
        lastPage: 0,
        currentPage: 0,
    },

    displayFormats: []
};

const getters = {
    getAll(state) {
        return state.all;
    },

    getPageDetails(state) {
        return state.pageDetails;
    },

    getDisplayFormats(state) {
        return state.displayFormats
    }
};

const mutations = {
    SET_MEDIA(state, { clearState, payload }) {
        state.pageDetails = {
            total: payload.total || 0,
            from: payload.from || 0,
            to: payload.to || 0,
            currentPage: payload.current_page || 0,
            lastPage: payload.last_page || 0,
            perPage: payload.per_page || 0
        }

        let all = payload.data ? payload.data : payload;

        if (clearState) {
            state.all = all;
        } else {
            state.all = [...state.all, ...all];
        }
    },

    REMOVE_MEDIA(state, id) {
        for (let [index, media] of state.all.entries()) {
            if (media.id == id) {
                state.all.splice(index, 1);
                return;
            }
        }
    },

    ADD_MEDIA(state, payload) {
        this.commit('REMOVE_MEDIA', payload.id);

        state.all.unshift(payload);
    },

    SET_DISPLAY_FORMATS(state,  payload)  {
        state.displayFormats =  payload;
    }
};

const actions = {
    index({ commit }, params) {
        return Service.index(params)
            .then(response => {
                commit('SET_MEDIA', { clearState: params.clearState, payload: response });
                return Promise.resolve(response);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    store({ commit }, data) {
        return Service.store(data)
            .then(response => {
                // commit('ADD_TICKET', response.ticket);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    storeOnBucket({ commit }, {presigned_url, file}) {
        return Service.storeOnBucket(presigned_url, file)
            .then(response =>  Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    deleteFileFromBucket({ commit }, id) {
        return Service.deleteFileFromBucket(id)
            .then(response =>  Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    update({ commit }, params) {
        return Service.update(params.id, params.data)
            .then(response => {
                // commit('UPDATE_TICKET', response.ticket);
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    show({ commit }, params) {
        return Service.show(params.id, params.params)
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error));
    },

    destroy({ commit }, id) {
        commit('REMOVE_MEDIA', id);

        return Service.destroy(id)
            .then(response => {
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    },

    getDisplayFormats({ commit }, params) {
        return Service.getDisplayFormats(params)
            .then(response => {
                commit('SET_DISPLAY_FORMATS',  response);
                return Promise.resolve(response)
            })
            .catch(error => Promise.reject(error));
    },
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}