<template>
    <transition name="router">
        <router-view/>
    </transition>
</template>

<script>

export default {
    name: 'AuthLayout',
}
</script>