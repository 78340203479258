<template>
    <div class="card project-box bg-pattern">
        <div class="card-body">
            <div class="dropdown float-end">
                <a href="#" class="dropdown-toggle card-drop arrow-none" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal m-0 text-muted h3"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" href="#" @click.prevent="$emit('edit')">Edit</a>
                    <a class="dropdown-item" href="#" @click.prevent="deleteAd">Delete</a>
                </div>
            </div> <!-- end dropdown -->
            <!-- Title-->
            <h4 class="mt-0">
                <router-link :to="detailsRoute" href="#" class="text-dark">
                    {{ advert.name }}
                </router-link>
            </h4>
            <p class="text-muted text-uppercase mb-1">
                <i class="mdi mdi-offer me-1"></i> 
                <small>{{ getOffer }}</small>
            </p>

            <div class="mb-2">
                <div class="badge bg-soft-secondary text-secondary text-capitalize">
                    {{ advert.product.name }}
                </div>
                <div class="badge bg-soft-success text-success text-capitalize ms-1" v-if="advert.status === 'active'">
                    Active
                </div>
                <div class="badge bg-soft-warning text-warning text-capitalize ms-1" v-if="advert.status === 'draft'">
                    Draft
                </div>
                <div class="badge bg-soft-danger text-danger text-capitalize ms-1" v-if="advert.status === 'trashed'">
                    Pending Deletion
                </div>
            </div>

            <!-- Desc-->
            <p class="text-muted font-13 mb-2 text-capitalize text-truncate sp-line-2" v-if="advert.promotional_claim">
                {{ advert.promotional_claim }}
            </p>

            <!-- Task info-->
            <div class="avatar-group mb-3" id="tooltips-container">
                <a href="javascript: void(0);" class="avatar-group-item" v-for="(media, index) in mediaFiles" :key="index">
                    <img 
                        :src="media.url" 
                        class="rounded-circle avatar-sm bg-white border elevation" 
                        :alt="media.name"
                        :class="{'p-1': !!media.padded}" />
                </a>
            </div>

            <p class="mb-0 fw-semibold fs-6" v-if="showExpiration">
                Expiration: <span class="float-end text-capitalize fs-6">{{ expiration | formattedDateTime }}</span>
            </p>

            <p class="mb-0 fw-semibold fs-6" v-if="showToBeDeleted">
                To Be Deleted On: <span class="float-end text-capitalize fs-6">{{ advert.scheduled_for_deletion_at | formattedDateTime }}</span>
            </p>

            <div class="progress mb-3" style="height: 7px;">
                <div class="progress-bar bg-warning"
                        role="progressbar" aria-valuenow="34" aria-valuemin="0" aria-valuemax="100"
                        :style="{'width': progressPercent + '%'}">
                </div>
            </div>

            <div class="d-flex justify-content-between">
                <div class="d-flex">
                    <span class="pe-2 text-nowrap d-inline-block me-1 bg-light p-1 fs-6">
                        <i class="mdi mdi-eye text-info me-1"></i>
                        <b>{{ advert.interactions.views || 0 }}</b>
                    </span>
                    <span class="text-nowrap d-inline-block me-1 bg-light p-1 fs-6">
                        <i class="mdi mdi-thumb-up-outline me-1 text-success"></i>
                        <b>{{ advert.interactions.likes || 0 }}</b>
                    </span>
                    <span class="text-nowrap d-inline-block me-1 bg-light p-1 fs-6">
                        <i class="mdi mdi-thumb-down-outline me-1 text-danger"></i>
                        <b>{{ advert.interactions.dislikes || 0 }}</b>
                    </span>
                    <span class="text-nowrap d-inline-block bg-light p-1 fs-6">
                        <i class="mdi mdi-cursor-default-click-outline me-1 text-danger"></i>
                        <b>{{ advert.interactions.clicks || 0 }}</b>
                    </span>
                </div>
                <button class="btn btn-soft-primary">
                    <i class="mdi mdi-chart-bar"></i>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
    props: {
        advert: {
            type: Object,
            required: true
        }
    },

    computed: {
        showToBeDeleted() {
            return this.advert.status === 'trashed' && this.advert.scheduled_for_deletion_at;
        },

        showExpiration() {
            return !['trashed', 'archived'].includes(this.advert.status);
        },

        expiration() {
            return this.advert.offer.expiration || this.advert.expiration;
        },

        progressPercent() {
            let start = this.showToBeDeleted ? this.advert.deleted_at : this.advert.created_at;
            let end = this.showToBeDeleted ? this.advert.scheduled_for_deletion_at : this.expiration;

            const source = moment(start);
            const target = moment(end);

            const now = moment();
            const duration = moment.duration(target.diff(source));
            const elapsed = moment.duration(now.diff(source));

            return Math.round((elapsed.asMilliseconds() / duration.asMilliseconds()) * 100);
        },

        getOffer() {
            if (!this.advert.offer) {
                return 'Offer not set';
            }

            const {type, x, y} = this.advert.offer;
            return type.replace('-x-', ` ${x} `)
                    .replace('-y-', ` ${y} `)
                    .replace('-y', ` ${y} `)
                    .replace('x-', ` ${x} `);
        },
        mediaFiles() {
            const files = this.advert.media_files;

            if (files.length === 0) {
                return this.advert.temp_media_files.map(file => {
                    return {
                        padded: true,
                        url: '/img/loader.gif',
                        name: file.key.split('/').pop()
                    }
                })
            }

            return files;
        },
        detailsRoute() {
            return {
                name: `listings.view`, 
                params: { 
                    advert_id: this.advert.id 
                }
            };
        }
    },

    methods: {
        ...mapActions({
            destroy: 'Adverts/destroy'
        }),

        async deleteAd() {
            const yes = await this.confirmDelete({
                title: 'Delete Advert',
                message: 'Are you sure you want to delete this advert?',
            });

            if (yes) {
                this.destroy(this.advert.id)
                    .then(() => this.$emit('refresh'))
                    .catch(() => null);
            }
        }
    },

    mounted() {
        // console.log(this.advert, this.expiration)
    }
}
</script>