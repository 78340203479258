<template>
    <div id="insights-filters">
        

        <div class="card mb-2">
            <div class="card-header bg-soft-primary" id="period">
                <h5 class="m-0">
                    <a class="text-dark" data-bs-toggle="collapse" href="#collapsePeriod" aria-expanded="true">
                        <i class="mdi mdi-earth me-1 text-primary"></i> 
                        Period
                    </a>
                </h5>
            </div>

            <div id="collapsePeriod" class="collapse show" aria-labelledby="period" data-bs-parent="#accordion">
                <div class="card-body">
                    <base-daterange
                        :noLabel="true"
                        placeholder="Period"
                        name="period"
                        range="Last 3 Months"
                        v-model="filters.period"
                    />
                </div>
            </div>
        </div>

        <div class="card mb-2">
            <div class="card-header bg-soft-primary" id="headingOne">
                <h5 class="m-0">
                    <a class="text-dark" data-bs-toggle="collapse" href="#collapseOne" aria-expanded="true">
                        <i class="mdi mdi-earth me-1 text-primary"></i> 
                        Demographics
                    </a>
                </h5>
            </div>

            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <base-tokens 
                                label="Countries" 
                                name="countries" 
                                placeholder="e.g United States, Canada"
                                v-model="filters.countries"
                                searchUrl="locations/countries"/>
                        </div>
                        <div class="col-12 mt-2" v-if="showStates">
                            <base-tokens 
                                label="States" 
                                name="states" 
                                placeholder="e.g California, Texas"
                                v-model="filters.states"
                                searchUrl="locations/states"
                                :query="getStatesQuery"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-2">
            <div class="card-header bg-soft-primary" id="headingTwo">
                <h5 class="m-0">
                    <a class="text-dark" data-bs-toggle="collapse" href="#collapseTwo" aria-expanded="false">
                        <i class="mdi mdi-target-account me-1 text-primary"></i> 
                        Psychographics
                    </a>
                </h5>
            </div>
            <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordion">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <label class="form-label">Age</label>
                            <div class="row">
                                <div class="col-5">
                                    <base-dropdown 
                                        id="age-lower" 
                                        color="white"
                                        v-model="filters.age_lower" 
                                        :options="lowerAgeRange"
                                    />
                                </div>
                                <div class="col-2 d-flex align-items-center">
                                    <i class="mdi mdi-format-horizontal-align-right fs-3 text-muted"></i>
                                </div>
                                <div class="col-5">
                                    <base-dropdown 
                                        id="age-upper" 
                                        color="white"
                                        v-model="filters.age_upper" 
                                        :options="upperAgeRange"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <label class="form-label">Gender</label>
                            <div class="">
                                <div class="btn-group d-flex">
                                    <button 
                                        v-for="(gender, index) in genders"
                                        :key="index"
                                        type="button" 
                                        class="btn text-capitalize border"
                                        @click="filters.gender = gender"
                                        :class="{
                                            'btn-soft-primary': filters.gender === gender,
                                            'btn-white': filters.gender !== gender
                                        }">
                                        {{ gender }}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mt-2">
                            <base-tokens 
                                label="Interests" 
                                name="interests" 
                                placeholder="1 or more interests"
                                v-model="filters.interests"
                                delimiter=";"
                                searchUrl="interests/types"/>
                        </div>

                        <div class="col-12 mt-2">
                            <base-tokens 
                                label="Product Categories" 
                                name="categories" 
                                placeholder="1 or more categories"
                                v-model="filters.categories"
                                delimiter=";"
                                searchUrl="products/types"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Config from '@/config';
import { debounce } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'InsightFilters',
    props: {
        value: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            filters: {
                period: '',
                countries: [],
                states: [],
                cities: [],
                age_lower: 18,
                age_upper: 'any',
                gender: 'any',
                interests: [],
                categories: [],
            },
        }
    },
    computed: {
        ...mapGetters({
            countries: 'Demographics/getCountries',
            states: 'Demographics/getStates',
            cities: 'Demographics/getCities',
        }),

        showStates() {
            return this.filters.countries.length > 0;
        },

        getStatesQuery() {
            return {
                countries: this.filters.countries.join(',')
            };
        },

        getCitiesQuery() {
            return {
                countries: this.filters.countries.join(','),
                states: this.filters.states.join(',')
            };
        },

        lowerAgeRange() {
            return Config.audiences.psychographics.age.lower;
        },

        upperAgeRange() {
            const items = Config.audiences.psychographics.age.upper.filter(
                age => +age > +this.filters.age_lower
            );
            return ['any', ...items];
        },

        genders() {
            return Config.audiences.psychographics.genders;
        }
    },
    watch: {
        filters: {
            handler: debounce(function () {
                this.emitData();
            }, 10),
            deep: true
        },
        'filters.country_id'() {
            this.filters.state_id = '';
            this.filters.city = '';
        },

        'filters.age_lower'() {
            if (this.filters.age_upper === 'any') {
                return;
            }

            if (+this.filters.age_upper <= +this.filters.age_lower) {
                this.filters.age_upper = 'any';
            }
        },

        'filters.interests'() {
            console.log(this.filters.interests);
        },
    },
    methods: {
        ...mapActions({
            fetchCountries: 'Demographics/index',
            fetchStates: 'Demographics/getStates',
            fetchCities: 'Demographics/getCities',
        }),

        emitData() {
            if (!this.filters.period) return;
            
            this.$emit('input', {...this.filters});
            this.$emit('search', {...this.filters});
        }
    },

    created() {
        this.emitData();
    }
}
</script>