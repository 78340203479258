<template>
  <div class="gs-mobile-nav responsive-page-width">
    <div class="gs-nav-con">
        <router-link
            :to="{ name: 'customer_dashboard' }"
            href="/customer/dashboard"
            active-class="active"
            class="icon-btn">
            <i class="mdi mdi-home icon mr-1"></i>
            <span>Dashboard</span>
        </router-link>

        <router-link
            :to="{ name: 'customer_list_channels' }"
            href="/customer/channels"
            active-class="active"
            class="icon-btn">
            <i class="mdi mdi-lightbulb-outline icon mr-1"></i>
            <span>Channels</span>
        </router-link>

        <div class="icon-btn plus-holder">
            <router-link
                :to="{ name: 'customer_create_intent' }"
                href="/customer/intents"
                class="icon-btn plus">
                <i class="mdi mdi-plus"></i>
            </router-link>
        </div>

        <router-link
            :to="{ name: 'customer_messaging' }"
            href="/customer/messaging"
            active-class="active"
            class="icon-btn">
            <i class="mdi mdi-email-outline"></i>
            <span>Messaging</span>
        </router-link>

        <router-link
            :to="{ name: 'customer_interests' }"
            href="/customer/interests"
            active-class="active"
            class="icon-btn">
            <i class="mdi mdi-shape-plus icon mr-1"></i>
            <span>Interests</span>
        </router-link>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>