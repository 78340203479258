import { axios } from './index';

export default {
    index(params) {
        return axios.get('coupons', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    store(data) {
        return axios.post('coupons', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    update(id, data) {
        return axios.put(`coupons/${id}`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    show(id, params) {
        return axios.get(`coupons/${id}`, { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    destroy(id) {
        return axios.delete(`coupons/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },
}