import { axios } from './index';

export default {
    /**
     * Get available topics
     *
     * @param {Object} params
     * @returns {Object}
     */
    index(params) {
        return axios.get('/initial-data/objectives', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },
}