import { axios } from './index';

export default {
    /**
     * Get available media_files
     *
     * @param {Object} params
     * @returns {Object}
     */
    index(params) {
        return axios.get('media_files', { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    /**
     * Store ticket
     *
     * @param {Object} data
     * @returns {Object}
     */
    store(data) {
        return axios.post('media_files', data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    storeOnBucket(presigned_url, file) {
        return axios.put(presigned_url, file)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    deleteFileFromBucket(id) {
        return axios.delete(`media_files/media/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    update(id, data) {
        return axios.put(`media_files/${id}`, data)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data));
    },

    show(id, params) {
        return axios.get(`media_files/${id}`, { params })
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    destroy(id) {
        return axios.delete(`media_files/${id}`)
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },

    getDisplayFormats(params) {
        return axios.get(`media_files/display_formats`, {params})
            .then(response => Promise.resolve(response.data))
            .catch(error => Promise.reject(error.response.data))
    },
}