<template>
    <div class="login-page v-stretch">

        <div class="account-pages mt-5 mb-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-4">
                        <div class="card bg-pattern">

                            <div class="card-body p-4">
                                
                                <div class="text-center w-75 m-auto">
                                    <div class="auth-brand">
                                        <a href="#" class="logo logo-dark text-center">
                                            <span class="logo-lg">
                                                <logo style="width: 100px;"/>
                                            </span>
                                        </a>
                    
                                        <a href="#" class="logo logo-light text-center">
                                            <span class="logo-lg">
                                                <logo/>
                                            </span>
                                        </a>
                                    </div>
                                    <p class="text-muted mb-4 mt-3 text-capitalize">
                                        Enter your login details.
                                    </p>
                                </div>

                                <form @submit.prevent="submit" method="POST">

                                    <div class="mb-3">
                                        <label for="emailaddress" class="form-label">Username, Email or Phone</label>
                                        <input
                                            class="form-control"
                                            type="text"
                                            placeholder="Username or Email or Phone"
                                            name="identity"
                                            v-model="credentials.identity"/>

                                        <span class="text-danger font-xs">{{ validationErrors.identity }}</span>
                                    </div>

                                    <div class="mb-3">
                                        <label for="password" class="form-label">Password</label>
                                        <div class="input-group input-group-merge">
                                            <input
                                                class="form-control"
                                                type="password"
                                                placeholder="Password"
                                                name="password"
                                                ref="password"
                                                id="password"
                                                v-model="credentials.password"
                                            />
                                            <button type="button" class="input-group-text" @click="togglePassword">
                                                <i class="mdi mdi-eye" v-if="!showPassword"></i>
                                                <i class="mdi mdi-eye-off" v-else></i>
                                            </button>
                                        </div>
                                        <span class="text-danger font-xs">{{ validationErrors.password }}</span>
                                    </div>

                                    <div class="mb-3">
                                        <div class="form-check">
                                            <input v-model="credentials.remember" type="checkbox" class="form-check-input" id="checkbox-signin" checked>
                                            <label class="form-check-label" for="checkbox-signin">Remember me</label>
                                        </div>
                                    </div>

                                    <div class="text-center d-grid">
                                        <button class="btn btn-primary" type="submit"> Log In </button>
                                    </div>

                                </form>

                                <div class="text-center">
                                    <h5 class="mt-3 text-muted">Sign in with</h5>

                                    <div class="d-flex gap-4 justify-content-center mb-0">
                                        <a class="">
                                            <a href="javascript: void(0);" class="border-primary text-primary">
                                                <i class="mdi mdi-facebook"></i>
                                            </a>
                                        </a>
                                        <a class="">
                                            <a href="javascript: void(0);" class="border-danger text-danger">
                                                <i class="mdi mdi-google"></i>
                                            </a>
                                        </a>
                                        <a class="">
                                            <a href="javascript: void(0);" class="border-info text-info">
                                                <i class="mdi mdi-twitter"></i>
                                            </a>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col-12 text-center">
                                <p> <a href="#" class="text-primary ms-1">Forgot your password?</a></p>
                                <p class="text-secondary">Don't have an account? <a href="#" class="text-primary ms-1"><b>Sign Up</b></a></p>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <verify-code
            :show="showVerify2FA"
            :twoFaMsg="twoFaMsg"
            :isSetup="false"
            @success="onVerify2FA"
            @cancel="showVerify2FA = false"/>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VerifyCode from '@/views/common/authentication/partials/Verify2FA.vue';

export default {
    name: 'Login',

    components: {
        VerifyCode
    },

    data() {
        return {
            showPassword: false,
            showVerify2FA: false,
            showLoginFailed: false,
            twoFaMsg: '',

            showPassword: false,
            credentials: {
                identity: '',
                password: '',
                remember: true,
            },
            validationErrors: {
                identity: '',
                password: '',
            },
        }
    },

    methods: {
        ...mapActions({
            login: 'Authentications/login',
        }),

        signup() {
            this.$router.push({name: 'register'});
        },

        handleTwoFa(msg) {
            console.log(msg);
            this.showVerify2FA = true;
            if (msg == 'invalid') {
                this.twoFaMsg = 'invalid';
            } else {
                this.twoFaMsg = '';
            }
            console.log(this.showVerify2FA)
        },

        onVerify2FA({code}) {
            this.submit(code);
        },

        submit(code = '') {
            this.showLoginFailed = false;
            this.showLoader('Authenticating... Please wait');

            this.validationErrors = {
                identity: '',
                password: '',
            };

            if (typeof code === 'object') {
                code = '';
            }

            this.twoFaMsg = '';

            this.login({...this.credentials, code})
                .then(response => {
                    this.hideLoader();
                    this.showVerify2FA = false;
                    setTimeout(() => {
                        this.goHome();
                    }, 0);
                })
                .catch(error => {
                    this.hideLoader();
                    if (error.two_fa) {
                        this.handleTwoFa(error.two_fa);
                    } else {
                        this.showLoginFailed = true;
                        this.buildValidationErrors(error.errors);
                    }
                });
        },

        goHome() {
            let route = null;

            const redirect = this.$route.query.redirect;

            if (redirect) {
                route = redirect;
            } else {
                route = {name: 'dashboard'};
            }

            if (route) {
                this.$router.push(route);
            }
        },

        togglePassword() {
            this.showPassword = !this.showPassword;

            if (this.showPassword) {
                this.$refs.password.setAttribute('type', 'text');
            } else {
                this.$refs.password.setAttribute('type', 'password');
            }
        }
    },

    created() {
        if (this.isLoggedIn) {
            this.goHome();
        }
    }
}
</script>