<template>
    <footer class="footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <div>
                        {{ currentYear }} © Cmunily - <a href="https://woonmi.com" target="_blank">Woonmi.com</a>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="d-none d-md-flex gap-4 align-item-center justify-content-md-end footer-links">
                        <a href="javascript: void(0);">About</a>
                        <a href="javascript: void(0);">Support</a>
                        <a href="javascript: void(0);">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    name: 'AppFooter',
    data() {
        return {
            currentYear: new Date().getFullYear()
        }
    }
}
</script>