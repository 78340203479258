import imageCompression from 'browser-image-compression';

export const resizeImage = async (file, options) => {
    options = {
        useWebWorker: true,
        ...options,
    }
  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error(error);
  }
};

export const createLowRes = async (file) => {
    const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 640,
    };
    const lowRes = await resizeImage(file, options);
    return lowRes;
};

export const createMedRes = async (file) => {
    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1280,
    };
    const medRes = await resizeImage(file, options);
    return medRes;
}

export const createHighRes = async (file) => {
    const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
    };
    const highRes = await resizeImage(file, options);
    return highRes;
}
