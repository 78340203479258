<template>
    <content-wrapper>
        <page-title title="Orders">
            <!-- <button @click.prevent="createAdvert" class="btn btn-soft-success">
                <i class="mdi mdi-plus-circle"></i> Create Campaign
            </button> -->
        </page-title>
    
        <div class="campaign-filters mb-3">
            <div class="row align-items-center">
                <div class="col-md-8 mb-3">
                    <div class="btn-group">
                        <button
                            v-for="(status, index) in statuses"
                            @click.prevent="setStatus(status.value)"
                            :class="{'text-primary': params.status == status.value}"
                            class="btn btn-secondary-success bg-white rounded-0"
                            type="button">
                            <i class="mdi" :class="status.icon"></i> {{ status.name }}
                        </button>
                    </div>
                </div>
                <div class="col-md-4 d-flex mb-3">
                    <form class="search-bar w-100">
                        <div class="position-relative">
                            <input v-model="params.keyword" 
                                type="text" class="form-control form-control-white" 
                                placeholder="Search by keyword...">
                            <span class="mdi mdi-magnify"></span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-centered table-nowrap mb-0">
                                <thead class="table-light">
                                    <tr>
                                        <th style="width: 20px;">
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck1">
                                                <label class="form-check-label" for="customCheck1">&nbsp;</label>
                                            </div>
                                        </th>
                                        <th>Order ID</th>
                                        <th>Products</th>
                                        <th>Date</th>
                                        <th>Payment Status</th>
                                        <th>Total</th>
                                        <th>Payment Method</th>
                                        <th>Order Status</th>
                                        <th style="width: 125px;">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck2">
                                                <label class="form-check-label" for="customCheck2">&nbsp;</label>
                                            </div>
                                        </td>
                                        <td>
                                            <router-link 
                                                :to="{name: 'orders.view', params: {order_id: 1}}" 
                                                href="#" class="text-body fw-bold">
                                                #UB9708
                                            </router-link> 
                                        </td>
                                        <td>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-1.png" alt="product-img" height="32" /></a>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-2.png" alt="product-img" height="32" /></a>
                                        </td>
                                        <td>
                                            August 05 2018 <small class="text-muted">10:29 PM</small>
                                        </td>
                                        <td>
                                            <h5><span class="badge bg-soft-success text-success"><i class="mdi mdi-bitcoin"></i> Paid</span></h5>
                                        </td>
                                        <td>
                                            $176.41
                                        </td>
                                        <td>
                                            Mastercard
                                        </td>
                                        <td>
                                            <h5><span class="badge bg-info">Shipped</span></h5>
                                        </td>
                                        <td>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck3">
                                                <label class="form-check-label" for="customCheck3">&nbsp;</label>
                                            </div>
                                        </td>
                                        <td>
                                            <router-link 
                                                :to="{name: 'orders.view', params: {order_id: 2}}" 
                                                href="#" class="text-body fw-bold">
                                                #UB9707
                                            </router-link> 
                                        </td>
                                        <td>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-3.png" alt="product-img" height="32" /></a>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-4.png" alt="product-img" height="32" /></a>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-5.png" alt="product-img" height="32" /></a>
                                        </td>
                                        <td>August 04 2018 <small class="text-muted">08:18 AM</small></td>
                                        <td>
                                            <h5><span class="badge bg-soft-warning text-warning"><i class="mdi mdi-timer-sand"></i> Awaiting Authorization</span></h5>
                                        </td>
                                        <td>
                                            $1,458.65
                                        </td>
                                        <td>
                                            Visa
                                        </td>
                                        <td>
                                            <h5><span class="badge bg-warning">Processing</span></h5>
                                        </td>
                                        <td>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck4">
                                                <label class="form-check-label" for="customCheck4">&nbsp;</label>
                                            </div>
                                        </td>
                                        <td><a href="ecommerce-order-detail.html" class="text-body fw-bold">#UB9706</a> </td>
                                        <td>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-7.png" alt="product-img" height="32" /></a>
                                        </td>
                                        <td>August 04 2018 <small class="text-muted">10:29 PM</small></td>
                                        <td>
                                            <h5><span class="badge bg-soft-success text-success"><i class="mdi mdi-bitcoin"></i> Paid</span></h5>
                                        </td>
                                        <td>
                                            $801.99
                                        </td>
                                        <td>
                                            Credit Card
                                        </td>
                                        <td>
                                            <h5><span class="badge bg-warning">Processing</span></h5>
                                        </td>
                                        <td>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck5">
                                                <label class="form-check-label" for="customCheck5">&nbsp;</label>
                                            </div>
                                        </td>
                                        <td><a href="ecommerce-order-detail.html" class="text-body fw-bold">#UB9705</a> </td>
                                        <td>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-3.png" alt="product-img" height="32" /></a>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-8.png" alt="product-img" height="32" /></a>
                                        </td>
                                        <td>August 03 2018 <small class="text-muted">07:56 AM</small></td>
                                        <td>
                                            <h5><span class="badge bg-soft-success text-success"><i class="mdi mdi-bitcoin"></i> Paid</span></h5>
                                        </td>
                                        <td>
                                            $215.35
                                        </td>
                                        <td>
                                            Mastercard
                                        </td>
                                        <td>
                                            <h5><span class="badge bg-success">Delivered</span></h5>
                                        </td>
                                        <td>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck6">
                                                <label class="form-check-label" for="customCheck6">&nbsp;</label>
                                            </div>
                                        </td>
                                        <td><a href="ecommerce-order-detail.html" class="text-body fw-bold">#UB9704</a> </td>
                                        <td>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-5.png" alt="product-img" height="32" /></a>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-7.png" alt="product-img" height="32" /></a>
                                        </td>
                                        <td>May 22 2018 <small class="text-muted">07:22 PM</small></td>
                                        <td>
                                            <h5><span class="badge bg-soft-danger text-danger"><i class="mdi mdi-cancel"></i> Payment Failed</span></h5>
                                        </td>
                                        <td>
                                            $2,514.36
                                        </td>
                                        <td>
                                            Paypal
                                        </td>
                                        <td>
                                            <h5><span class="badge bg-danger">Cancelled</span></h5>
                                        </td>
                                        <td>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck7">
                                                <label class="form-check-label" for="customCheck7">&nbsp;</label>
                                            </div>
                                        </td>
                                        <td><a href="ecommerce-order-detail.html" class="text-body fw-bold">#UB9703</a> </td>
                                        <td>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-2.png" alt="product-img" height="32" /></a>
                                        </td>
                                        <td>April 02 2018 <small class="text-muted">03:02 AM</small></td>
                                        <td>
                                            <h5><span class="badge bg-soft-success text-success"><i class="mdi mdi-bitcoin"></i> Paid</span></h5>
                                        </td>
                                        <td>
                                            $183.20
                                        </td>
                                        <td>
                                            Payoneer
                                        </td>
                                        <td>
                                            <h5><span class="badge bg-info">Shipped</span></h5>
                                        </td>
                                        <td>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck8">
                                                <label class="form-check-label" for="customCheck8">&nbsp;</label>
                                            </div>
                                        </td>
                                        <td><a href="ecommerce-order-detail.html" class="text-body fw-bold">#UB9702</a> </td>
                                        <td>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-4.png" alt="product-img" height="32" /></a>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-6.png" alt="product-img" height="32" /></a>
                                        </td>
                                        <td>March 18 2018 <small class="text-muted">11:19 PM</small></td>
                                        <td>
                                            <h5><span class="badge bg-soft-warning text-warning"><i class="mdi mdi-timer-sand"></i> Awaiting Authorization</span></h5>
                                        </td>
                                        <td>
                                            $1,768.41
                                        </td>
                                        <td>
                                            Visa
                                        </td>
                                        <td>
                                            <h5><span class="badge bg-warning">Processing</span></h5>
                                        </td>
                                        <td>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck9">
                                                <label class="form-check-label" for="customCheck9">&nbsp;</label>
                                            </div>
                                        </td>
                                        <td><a href="ecommerce-order-detail.html" class="text-body fw-bold">#UB9701</a> </td>
                                        <td>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-6.png" alt="product-img" height="32" /></a>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-8.png" alt="product-img" height="32" /></a>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-3.png" alt="product-img" height="32" /></a>
                                        </td>
                                        <td>February 01 2018 <small class="text-muted">07:22 AM</small></td>
                                        <td>
                                            <h5><span class="badge bg-soft-info text-info"><i class="mdi mdi-cash"></i> Cash on Delivery</span></h5>
                                        </td>
                                        <td>
                                            $3,582.99
                                        </td>
                                        <td>
                                            Paypal
                                        </td>
                                        <td>
                                            <h5><span class="badge bg-info">Shipped</span></h5>
                                        </td>
                                        <td>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck10">
                                                <label class="form-check-label" for="customCheck10">&nbsp;</label>
                                            </div>
                                        </td>
                                        <td><a href="ecommerce-order-detail.html" class="text-body fw-bold">#UB9700</a> </td>
                                        <td>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-2.png" alt="product-img" height="32" /></a>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-5.png" alt="product-img" height="32" /></a>
                                        </td>
                                        <td>January 22 2018 <small class="text-muted">08:09 PM</small></td>
                                        <td>
                                            <h5><span class="badge bg-soft-success text-success"><i class="mdi mdi-bitcoin"></i> Paid</span></h5>
                                        </td>
                                        <td>
                                            $923.95
                                        </td>
                                        <td>
                                            Credit Card
                                        </td>
                                        <td>
                                            <h5><span class="badge bg-success">Delivered</span></h5>
                                        </td>
                                        <td>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="form-check">
                                                <input type="checkbox" class="form-check-input" id="customCheck11">
                                                <label class="form-check-label" for="customCheck11">&nbsp;</label>
                                            </div>
                                        </td>
                                        <td><a href="ecommerce-order-detail.html" class="text-body fw-bold">#UB9699</a> </td>
                                        <td>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-7.png" alt="product-img" height="32" /></a>
                                            <a href="ecommerce-product-detail.html"><img src="/theme/images/products/product-8.png" alt="product-img" height="32" /></a>
                                        </td>
                                        <td>January 17 2018 <small class="text-muted">02:30 PM</small></td>
                                        <td>
                                            <h5><span class="badge bg-soft-success text-success"><i class="mdi mdi-bitcoin"></i> Paid</span></h5>
                                        </td>
                                        <td>
                                            $5,177.68
                                        </td>
                                        <td>
                                            Mastercard
                                        </td>
                                        <td>
                                            <h5><span class="badge bg-info">Shipped</span></h5>
                                        </td>
                                        <td>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-eye"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-square-edit-outline"></i></a>
                                            <a href="javascript:void(0);" class="action-icon"> <i class="mdi mdi-delete"></i></a>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <empty v-if="!all.length" title="No Ad found"/>    
    </content-wrapper>
    </template>
    
    <script>
    import { mapActions, mapGetters } from 'vuex';
    
    export default {
        data() {
            return {
                statuses: [
                    {name: 'All', value: 'all', icon: 'mdi-format-list-bulleted'},
                    {name: 'Launched', value: 'active', icon: 'mdi-radiobox-marked'},
                    {name: 'Paused', value: 'paused', icon: 'mdi-stop-circle-outline'},
                    {name: 'Draft', value: 'draft', icon: 'mdi-paperclip'},
                    {name: 'Archived', value: 'archived', icon: 'mdi-archive'},
                    {name: 'Trashed', value: 'trashed', icon: 'mdi-trash-can'},
                ],
                params: {
                    status: 'all',
                    keyword: '',
                }
            }
        },
    
        computed: {
            ...mapGetters({
                all: 'Campaigns/getAll',
                pageDetails: 'Campaigns/getPageDetails'
            })
        },
    
        watch: {
        },
    
        methods:{
            ...mapActions({
                index: 'Campaigns/index',
                store: 'Campaigns/store',
                fetchCatalogs: 'Catalogs/index',
            }),
    
            setStatus(value) {
                this.params.status = value;
                this.performFiltering();
            },
    
            performFiltering() {
                this.loadData({clearState: true});
            },
    
            loadData(query) {
                let params = {
                    paginate: true,
                    per_page: 20,
                    ...query,
                    ...this.params,
                    keyword: this.keyword
                }
    
                this.loading = true;
    
                this.showLoader('Loading ads... Please wait');
    
                this.index(params)
                    .then(response => {
                        this.hideLoader();
                        console.log(response);
                    })
                    .catch(error => {
                        this.loading = false;
                        this.hideLoader();
                    })
            }
        },
    
        created() {
            // this.loadData({clearState: true});
        },
    }
    </script>