<template>
    <modal
        :show="show"
        :centered="true"
        :md="true"
        okText="Create"
        @cancel="cancel"
        okIcon="mdi mdi-plus"
        @ok="submit">
    
        <h5 class="font-weight-bold mb-3" slot="header">Create New Campaign</h5>
    
    
        <div class="font-small" slot="body">
            <div class="row">
                <div class="col-12">
                    <base-input 
                        label="Name" 
                        :required="true"
                        v-model="payload.name" 
                        placeholder="Enter name"/>

                    <div class="row mt-2">
                        <div class="col-6">
                            <base-tokens 
                                label="Advert" 
                                name="advert" 
                                placeholder="Search Ad by keyword"
                                v-model="payload.adverts"
                                itemClass="text-secondary"
                                :query="{'slim': 1}"
                                :maxLenght="1"
                                :required="true"
                                searchUrl="listings"/>
                        </div>
                        <div class="col-6">
                            <base-tokens 
                                label="Audience" 
                                name="audience" 
                                placeholder="Search Audience by keyword"
                                v-model="payload.audiences"
                                itemClass="text-secondary"
                                :query="{'slim': 1}"
                                :maxLenght="1"
                                :required="true"
                                searchUrl="audience"/>
                        </div>
                    </div>

                    <div class="row mt-2 mb-3">
                        <div class="col-6">
                            <base-select
                                label="Objective"
                                name="objective"
                                :required="true"
                                v-model="payload.objective">
                                <option value="">Select Objective</option>
                                <option v-for="(objective, index) in objectives" :key="index" :value="objective">
                                    {{ objective }}
                                </option>
                            </base-select>
                        </div>
    
                        <div class="col-6">
                            <base-select
                                label="Matching Strategy"
                                name="strategy"
                                :required="true"
                                v-model="payload.strategy">
                                <option value="">Select Strategy</option>
                                <option v-for="(strategy, index) in strategies" :key="index" :value="strategy">
                                    {{ strategy }}
                                </option>
                            </base-select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <base-daterange
                                placement="left"
                                label="Duration"
                                placeholder="Select Start and End Date"
                                name="duration"
                                :noRanges="true"
                                :autoUpdate="false"
                                drops="auto"
                                v-model="duration"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </modal>
    </template>
    <script>
    import { mapActions, mapGetters } from 'vuex';
    import FieldDescription from '@/components/shared/field-explainer/FieldDescription.vue';
    
    export default {
        props: ['show', 'campaign'],
    
        components: {
            FieldDescription
        },
    
        data() {
            return {
                duration: '',

                payload: {
                    strategy: '',
                    objective: '',
                    name: '',
                    adverts: [],
                    audiences: [],
                },
    
                objectives: [
                    'awareness',
                    'conversion',
                ],
                strategies: [
                    'intent',
                    'interest'
                ],
            }
        },
    
        computed: {
            ...mapGetters({
            })
        },
    
        watch: {
            show() {
                this.reset()
            },
    
            campaign() {
                this.setCampaign()
            },
        },
    
        methods: {
            ...mapActions({
                store: 'Campaigns/store',
            }),
    
            setCampaign() {
                if (!this.campaign || !this.campaign.advert) return;
    
                let { advert } = this.campaign;
    
                this.payload.name = advert.name;
                this.payload.intent_type_id = advert.intent_type_id;
                this.payload.objective = advert.objective;
                this.payload.description = advert.description;
            },
    
            reset() {
                this.payload.name = '';
                this.payload.objective = '';
                this.payload.strategy = '';
                this.payload.advert = [];
                this.payload.audience = [];
            },
    
            cancel() {
                this.reset();
                this.$emit('cancel');
            },
    
            submit() {
                this.showLoader('Creating Campaign... Please wait');

                const [start_date, end_date] = this.duration.split(' to ');
                const data = {
                    ...this.payload,
                    start_date,
                    end_date
                }

                this.store(data)
                    .then(response => {
                        this.hideLoader();
                        this.cancel();
    
                        this.$router.push({
                            name: 'campaigns.view', 
                            params: {
                                campaign_id: response.id
                            }
                        });
                    })
                    .catch(error => {
                        this.hideLoader();
                        this.swalError('Error Creating Campaign', error.message || 'An error occurred while creating campaign');
                    })
            }
        },
    
        created() {
        },
    
        mounted() {
            this.setCampaign();
        }
    }
    </script>