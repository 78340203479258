import Login from '@/views/common/authentication/Login.vue';
import Register from '@/views/common/authentication/Register.vue';
import VerifyEmail from '@/views/common/verifications/Email.vue';
import ResendEmail from '@/views/common/verifications/ResendEmail.vue';
import ResetPassword from '@/views/common/authentication/ResetPassword.vue';
import ForgotPassword from '@/views/common/authentication/ForgotPassword.vue';

import routes from './modules';

let links = [
    {
        path: '/',
        name: 'welcome',
        redirect: '/login'
    },

    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: 'Login'
        }
    },

    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            title: 'Register'
        }
    },

    {
        path: '/passwords/forgot',
        name: 'forgot_password',
        component: ForgotPassword,
        meta: {
            title: 'Forgot Password'
        }
    },

    {
        path: '/passwords/reset/:token',
        name: 'reset_password',
        props: true,
        component: ResetPassword,
        meta: {
            title: 'Reset Password'
        }
    },

    {
        path: `/verifications/email/:token?`,
        name: 'verify_email',
        props: true,
        component: VerifyEmail,
        meta: {
            title: `Verify Email Address`
        }
    },

    {
        path: `/verifications/resend_email`,
        name: 'resend_email',
        props: true,
        component: ResendEmail,
        meta: {
            title: `Verify Email Address`
        }
    },

    ...routes
];

export default links;