import { get } from 'lodash';
import { axios } from '@/services';

export const initEditor = (selector, {onChange, value = ''}, theme = 'snow') => {
    const editor = new Quill(selector, {
        theme: theme,
        modules: {
            'toolbar': [
                [ { 'size': [] } ], 
                ['bold', 'italic', 'underline', 'strike'], 
                [{ 'color': [] }, { 'background': [] }], 
                // [{ 'script': 'super' }, { 'script': 'sub' }], 
                // [{ 'header': [false, 1, 2, 3, 4, 5, 6] }, 'blockquote', 'code-block'], 
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }], 
                // ['direction', { 'align': [] }], 
                // ['link'], ['clean']
            ]
        },
    });

    if (value) {
        editor.clipboard.dangerouslyPasteHTML(value);
    }

    if (onChange) {
        editor.on('text-change', () => {
            onChange(editor.root.innerHTML);
        });
    }

    return editor;
};

export const initSelect2 = (selector = '.select2') => {
    $(selector).select2({ width: '100%' });
};

export const initSelectize = (selector, config = {}) => {
    $(selector).each(function () {
        const name = $(this).attr('name');
        const { onChange, onDelete, searchUrl, getQuery, itemClass,  ...rest } = config;

        $(this).selectize({
            plugins: ['remove_button'],
            persist: false,
            create: true,
            render: {
                item: function(data, escape) {
                    let text = get(data, rest.valueField, data.text);
                    const emoji = get(data, 'emoji', '');
                    return `
                        <div class="${itemClass ? itemClass : 'bg-light text-primary'}">
                            ${emoji ? emoji : ''}
                            ${escape(text)}
                        </div>
                    `;
                },
                option: function(data, escape) {
                    let text = get(data, rest.valueField, data.text);
                    const emoji = get(data, 'emoji', '');
                    return `
                        <div class="ps-1 pe-1 mb-1 border-bottom">
                            ${emoji}
                            ${escape(text)}
                        </div>
                    `;
                }
            },
            onDelete: function(value) {
                if (onDelete) {
                    onDelete({ name, value});
                }
            },
            onChange: function(value) {
                if (onChange) {
                    onChange({name, value});
                }
            },
            load: function(keyword, callback) {
                const query = getQuery ? getQuery() : {};
                if (!searchUrl) return;
                const params = { ...query, keyword };
                // console.log(params);
                axios.get(searchUrl, { params })
                .then(response => {
                    callback(response.data);
                });
            },
            ...rest,
        });
    });
}

export const initDropzone = (selector, config = {}) => {
    let myDropzone = new window.Dropzone(selector, {
        url: get(config, 'url', 'dummy'),
        autoProcessQueue: false,
        acceptedFiles: get(config, 'acceptedFiles', 'image/*'),
        addRemoveLinks: true,
        dictRemoveFile: 'Remove',
        method: 'put',
        headers: {
            'Cache-Control': 'public,max-age=31536000,immutable',
            'x-amz-acl': 'public-read',
        },
        paramName: () => {
            return null; // Ensures the file itself is sent as the request body
        },
        sending: function(file, xhr, formData) {
            xhr.setRequestHeader("Content-Type", file.type);
        }
    });

    const onGetUploadUrl = get(config, 'onGetUploadUrl', null);
    if (onGetUploadUrl) {
        myDropzone.on("processing", function (file) {
            this.options.url = onGetUploadUrl(file);
        });
    }

    const onAddedFile = get(config, 'onAddedFile', null);
    if (onAddedFile && typeof onAddedFile === 'function') {
        myDropzone.on("addedfile", file => {
            onAddedFile(file);
        });
    }

    const onRemovedFile = get(config, 'onRemovedFile', null);
    if (onRemovedFile && typeof onRemovedFile === 'function') {
        myDropzone.on("removedfile", file => {
            onRemovedFile(file);
        });
    }

    return myDropzone;
};

export const processDropzoneQueue = (dropzone, { presignedUrls, onUploadProgress, onCompleted }) => {
    if (!dropzone) {
        throw new Error('Dropzone instance is required');
    };

    let completed_files = 0;

    dropzone.on("processing", function (file) {
        if (presignedUrls && presignedUrls[file.upload.uuid]) {
            this.options.url = presignedUrls[file.upload.uuid];
            console.log(file, this.options)
        }
    });

    dropzone.on("complete", function (file) {
        if (onUploadProgress) {
            onUploadProgress(file);
        }

        completed_files++;

        if (completed_files === this.files.length) {
            if (onCompleted) {
                onCompleted(file);
            }
        }
    });

    dropzone.processQueue();
};