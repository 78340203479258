<template>
    <content-wrapper class="">
        <page-title title="Manager Members">
            <button @click.prevent="showAddMember = true" class="btn btn-soft-success">
                <i class="mdi mdi-email-outline"></i> Invite New Member
            </button>
        </page-title>

        <div class="campaign-filters mb-3">
            <div class="row align-items-center">
                <div class="col-md-8">
                    <div class="mb-3">
                        <button
                            @click.prevent="params.status = 'all'"
                            :class="{'text-primary': params.status == 'all'}"
                            class="btn btn-secondary-success bg-white rounded-0"
                            type="button">
                            <i class="mdi mdi-format-list-bulleted"></i> All
                        </button>

                        <button
                            @click.prevent="params.status = 'active'"
                            :class="{'text-primary': params.status == 'active'}"
                            class="btn btn-secondary-success bg-white rounded-0"
                            type="button">
                            <i class="mdi mdi-checkbox-marked-circle"></i> Active
                        </button>

                        <button
                            @click.prevent="params.status = 'invited'"
                            class="btn btn-secondary-success bg-white rounded-0"
                            :class="{'text-primary': params.status == 'invited'}"
                            type="button">
                            <i class="mdi mdi-email-outline"></i> Invited
                        </button>

                        <button
                            @click.prevent="params.status = 'disabled'"
                            class="btn btn-secondary-success bg-white rounded-0"
                            :class="{'text-primary': params.status == 'disabled'}"
                            type="button">
                            <i class="mdi mdi-block-helper"></i> Disabled
                        </button>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="mb-3">
                        <form class="search-bar">
                            <div class="position-relative">
                                <input v-model="keyword" 
                                    type="text" class="form-control form-control-white" 
                                    placeholder="Search team member...">
                                <span class="mdi mdi-magnify"></span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <app-card>
            <empty title="No team member found" v-if="!all.length"/>

            <div class="table-responsive">
                <table class="table font-small">
                    <tbody>
                        <tr v-for="(item, index) in all" :key="index">
                            <td>
                                <div class="d-flex">
                                    <img :src="item.user.image_url" class="avatar-sm mr-2 image-border circle"/>
                                    <div class="flex-grow-1">
                                        <b>{{ item.user.fullname }}</b><br/>
                                        <small class="text-info">@{{ item.user.username }}</small>
                                        <p class="font-xs m-0 text-muted">{{ item.user.email }}</p>
                                    </div>
                                </div>
                            </td>

                            <td class="text-no-wrap">
                                <button
                                    v-if="item.status == 'active'"
                                    class="status-btn font-xs text-success"
                                    style="border-radius:50px;"
                                    type="button">
                                    <i class="mdi mdi-checkbox-marked-circle"></i> Active
                                </button>

                                <button
                                    v-else-if="item.status == 'disabled'"
                                    class="status-btn font-10 text-danger"
                                    style="border-radius:50px;"
                                    type="button">
                                    <i class="mdi mdi-block-helper"></i> Disabled
                                </button>

                                <button
                                    v-else
                                    class="status-btn font-10 text-primary"
                                    style="border-radius:50px;"
                                    type="button">
                                    <i class="mdi mdi-email-outline"></i> Invited
                                </button>
                            </td>

                            <td class="text-right text-no-wrap">
                                <button
                                    class="status-btn font-xs danger"
                                    type="button"
                                    @click="deleteMember(item)">
                                    <i class="mdi mdi-block-helper mr-1"></i> Disable
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </app-card>

        <add-member
            :member="member"
            :show="showAddMember"
            @done="loadData"
            @cancel="addMember(null)"/>
    </content-wrapper>
</template>

<script>
import {debounce} from 'lodash';
import AddMember from './partials/AddMember';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
        AddMember
    },

    data() {
        return {
            keyword: '',
            member: null,
            showAddMember: false,

            params: {
                status: 'all'
            }
        }
    },

    computed: {
        ...mapGetters({
            all: 'MerchantTeamMembers/getAll'
        })
    },

    watch: {
        params: {
            handler() {
                this.loadData();
            },
            deep: true
        }
    },

    methods: {
        ...mapActions({
            index: 'MerchantTeamMembers/index',
            destroy: 'MerchantTeamMembers/destroy',
        }),

        addMember(member) {
            this.showAddMember = !!member;
            this.member = member;
        },

        async deleteMember(member) {
            let result = await this.swalConfirm(`Are you sure?`, `Please confirm you wish to delete this member from your team`, `Yes, Delete`);

            if (!result.value) {
                return;
            }

            this.showLoader();

            this.destroy(member.id)
                .then(response => {
                    this.hideLoader();
                    this.loadData();
                })
                .catch(error => {
                    this.hideLoader();
                })
        },

        loadData(query) {
            let params = {
                ...query,
                ...this.params,
                keyword: this.keyword
            }

            this.showLoader('Getting team members... Please wait')

            this.index(params)
                .then(response => {
                    this.hideLoader()
                })
                .catch(error => {
                    this.hideLoader()
                })
        }
    },

    created() {
        this.loadData();
    }
}
</script>