
import Campaigns from '@/views/merchant/campaigns/Index.vue';
import Adverts from '@/views/merchant/adverts/Index.vue';
import ViewCampaign from '@/views/merchant/campaigns/View.vue';
import ManageMembers from '@/views/merchant/members/Index.vue';
import Dashboard from '@/views/merchant/dashboard/Index.vue';
import ProfilePage from '@/views/merchant/profile/Index.vue'
import SupportTickets from '@/views/merchant/tickets/Index.vue';

import AudienceInsights from '@/views/merchant/audience/Insights.vue'

import Catalogs from '@/views/merchant/catalogs/Index.vue';
import CatalogProducts from '@/views/merchant/catalogs/Products.vue';
import ViewProductDetails from '@/views/merchant/catalogs/ViewProduct.vue';
import Media from '@/views/merchant/media/Index.vue';
import Coupons from '@/views/merchant/coupons/Index.vue';

import Orders from '@/views/merchant/orders/Index.vue';
import ViewOrder from '@/views/merchant/orders/View.vue';

let routes = [
    {
        name: 'dashboard',
        path: 'dashboard',
        component: Dashboard,
        meta: {
            title: 'Dashboard'
        }
    },

    {
        name: 'campaigns.list',
        path: 'campaigns',
        component: Campaigns,
        meta: {
            title: 'Campaigns'
        }
    },

    {
        name: 'campaigns.view',
        path: 'campaigns/:campaign_id',
        component: ViewCampaign,
        props: true,
        meta: {
            title: 'View Campaign'
        }
    },

    {
        name: 'listings.list',
        path: 'listings',
        component: Adverts,
        meta: {
            title: 'Ads'
        }
    },

    {
        name: 'audiences',
        path: 'audiences',
        component: AudienceInsights,
        meta: {
            title: 'Audience Insights'
        }
    },

    {
        name: 'members.list',
        path: 'members',
        component: ManageMembers,
        meta: {
            title: 'Manage Members'
        }
    },

    {
        name: 'profile',
        path: 'profile',
        component: ProfilePage,
        meta: {
            title: 'Profile Page'
        }
    },

    {
        name: 'support_tickets',
        path: 'tickets',
        component: SupportTickets,
        meta: {
            title: 'Support Tickets'
        }
    },

    {
        name: 'files.library',
        path: 'files/library',
        component: Media,
        meta: {
            title: 'Files Library'
        }
    },
    
    {
        name: 'catalogs',
        path: 'catalogs/lists',
        component: Catalogs,
        meta: {
            title: 'Catalogs'
        }
    },

    {
        name: 'catalogs.products',
        path: 'catalogs/products',
        component: CatalogProducts,
        meta: {
            title: 'Catalog Products'
        }
    },

    {
        name: 'catalogs.products.view',
        path: 'catalogs/products/:product_id',
        component: ViewProductDetails,
        props: true,
        meta: {
            title: 'Product Details'
        }
    },

    {
        name: 'coupons',
        path: 'coupons',
        component: Coupons,
        meta: {
            title: 'Coupons'
        }
    },  
    
    {
        name: 'orders.list',
        path: 'orders',
        component: Orders,
        meta: {
            title: 'Orders'
        }
    },

    {
        name: 'orders.view',
        path: 'orders/:order_id',
        component: ViewOrder,
        props: true,
        meta: {
            title: 'View Order'
        }
    }
]

export default routes;