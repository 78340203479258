<template>
    <modal
        :show="show"
        :showFooter="showFooter"
        :centered="false"
        :xl="true"
        :fixedHeight="true"
        :okText="okButton.text"
        @cancel="cancel"
        :okIcon="okButton.icon"
        @ok="navigate"
        okClass="btn btn-soft-primary">
    
        <h5 class="m-0" slot="header">
            Provide Product Details
        </h5>

        <div class="" slot="body">
            <div class="d-flex justify-content-center sticky-container bg-white shadow" style="top:-1px">
                <div class="btn-group mb-2">
                    <button 
                        v-for="(step, index) in steps" 
                        :key="step" 
                        type="button" 
                        class="btn"
                        :class="{'btn-soft-success': activeStep >= index}"
                        @click.prevent="activeStep = index">
                        <span class="badge bg-white text-primary ms-1">
                            {{ index + 1 }}.
                        </span>
                        {{ step }} 
                    </button>
                </div>
            </div>

            <general v-if="activeStep === 0"/>
            <features v-if="activeStep === 1"/>
            <images v-show="activeStep === 2"/>
            <edit-meta v-if="activeStep === 3"/>
            
        </div>
    </modal>
 </template>
<script>
import { mapActions, mapGetters } from 'vuex';
import General from './partials/General.vue'
import Features from './partials/Features.vue'
import EditMeta from './partials/Meta.vue'
import Images from './partials/Images.vue'

export default {
    props: ['show', 'catalog_id'],

    components: {
        EditMeta,
        General,
        Features,
        // Categories,
        Images,
    },

    data() {
        return {
            activeStep: -1,
            steps: ['General', 'Features', 'Images', 'Meta Info'],
        }
    },

    computed: {
        ...mapGetters({
            payload: 'ProductsCreate/getFormValuesReqPayload',
            formValues: 'ProductsCreate/getFormValues'
        }),

        isEditProd() {
            return !!this.formValues.id;
        },

        showFooter() {
            return true;
        },
        
        okButton() {
            if (this.isEditProd || this.activeStep === this.steps.length - 1) {
                return {
                    text: 'Save Product',
                    icon: 'mdi mdi-plus'
                };
            }
            
            return {
                text: 'Next',
                icon: 'mdi mdi-arrow-right'
            };
        },
    },

    watch: {
        show() {
            if (this.show) {
                this.activeStep = 0;
            } else {
                this.activeStep = -1;
            }
        }
    },

    methods: { 
        ...mapActions({
            store: 'Products/store',
            update: 'Products/update',
            clearState: 'ProductsCreate/clearState'
        }),

        cancel() {
            this.clearState();
            this.$emit('cancel');
        },

        navigate() {
            if (this.isEditProd || this.activeStep === this.steps.length - 1) {
                this.submit();
            } else {
                this.activeStep++;
            }
        },

        submit() {
            this.showLoader(`Saving Product... Please wait`);

            let method = this.payload.id ? this.update : this.store;
            const data = {...this.payload, catalog_id: this.catalog_id, action: 'update-all' };
            console.log(data);
            method(data).then(async (response) => {
                this.hideLoader();
                await this.uploadImages(response);
            })
            .catch((error) => {
                this.hideLoader();
                this.swalError(
                    `Could not Save Product`, 
                    error.message || 'An error occurred while saving product'
                )
            });
        },

        async uploadImages(response) {
            const presignedUrls = response.presigned_urls;
            const product = response.product;

            const dropzone = this.formValues.dropzone;
            
            if (presignedUrls.length === 0) {
                this.hideLoader();
                this.cancel();
                return;
            }

            this.showLoader('Uploading images... Please wait');

            this.processDropzoneQueue(dropzone, {
                presignedUrls: presignedUrls,
                onCompleted: async () => {
                    await this.update({...product, action:'update-images'});
                    this.hideLoader();
                    this.cancel();
                }
            })
        }
    }
}
</script>