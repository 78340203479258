<template>
    <content-wrapper>
        <page-title title="Catalogs">
            <a href="#" @click.prevent="createCatalog(true)" class="btn btn-soft-success waves-effect waves-light">
                <i class="mdi mdi-plus"></i> Create Catalog
            </a>
        </page-title>
    
        <div class="filters mb-3">
            <div class="">
                <button 
                    type="button" 
                    @click="params.status = 'all'" 
                    :class="{'text-primary': params.status === 'all'}"
                    class="btn btn-secondary-success bg-white rounded-0 text-capitalize">
                    <i class="mdi mdi-format-list-bulleted"></i> All
                </button>
                <button 
                    type="button" 
                    @click="params.status = 'active'" 
                    :class="{'text-primary': params.status === 'active'}"
                    class="btn btn-secondary-success bg-white rounded-0 text-capitalize">
                    <i class="mdi mdi-checkbox-marked-circle"></i> active
                </button>
                <button 
                    type="button" 
                    @click="params.status = 'archived'" 
                    :class="{'text-primary': params.status === 'archived'}"
                    class="btn btn-secondary-success bg-white rounded-0 text-capitalize">
                    <i class="mdi mdi-archive-outline"></i> archived
                </button>
            </div>
        </div>

        <div class="catalogs">
            <div class="row">
                <div class="col-lg-4" v-for="(catalog, index) in all" :key="index">
                    <catalog-item :catalog="catalog"/>
                </div>
            </div>
        </div>
    
        <create-catalog 
            :show="showCreateCatalog"
            @cancel="createCatalog(false)" />
    </content-wrapper>
</template>

<script>
import config from '@/config.js';
import { mapActions, mapGetters } from 'vuex';
import CatalogItem from '@/views/merchant/catalogs/partials/CatalogItem.vue';
import CreateCatalog from '@/views/merchant/catalogs/partials/CreateCatalog.vue';

export default {
    components: {
        CatalogItem,
        CreateCatalog
    },

    data() {
        return {
            showCreateCatalog: false,
            params: {
                keyword: '',
                status: 'all'
            }
        }
    },

    computed: {
        ...mapGetters({
            all: 'Catalogs/getAll',
            pageDetails: 'Catalogs/getPageDetails'
        }),

        types() {
            return config.catalogs.types;
        }
    },

    watch: {
        params: {
            handler() {
                this.loadData({clearState: true});
            },
            deep: true
        }
    },

    methods:{
        ...mapActions({
            index: 'Catalogs/index',
            store: 'Catalogs/store'
        }),

        loadData(query) {
            let params = {
                paginate: true,
                per_page: 20,
                ...query,
                ...this.params,
            }

            this.loading = true;

            this.showLoader('Loading ads... Please wait');

            this.index(params)
                .then(response => {
                    this.hideLoader();

                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.hideLoader();
                })
        },

        createCatalog(show = true) {
            this.showCreateCatalog = show;
        },

        showPartialsModal(key) {
            this.showModals[key] = true;
        },
    },

    created() {
        this.loadData({clearState: true});
    }
}
</script>