<template>
    <div class="app-loader d-flex justify-content-center align-items-center">
        <div class="inner-loader d-flex align-items-center">
            <div class="loader me-2"></div>
            <div class="">{{ text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['text']
}
</script>

<style lang="scss">
    .app-loader{
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        color: white;
        z-index: 10000;
        position: fixed;
        overflow: hidden;
        background: rgba(black, 0.7);

        .loader {
            border: 3px solid #eee; /* Light grey */
            border-top-color: lighten(#D6092C, 12%); /* Blue */
            border-radius: 50%;
            width: 40px;
            height: 40px;
            animation: spin 0.5s linear infinite;
        }

        .inner-loader{
            background: white;
            color: #D6092C !important;
            flex-direction: row !important;
            // width: 70%;
            padding: 20px;
            font-size: 12px !important;
        }

        @media screen and (max-width: 768px) {
            .loader{
                border-width: 2px;
                width: 30px;
                height: 30px;
                margin-right: 20px;
            }
        }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>
