<template>
    <div class="card">
        <div class="card-body">
            <base-tab v-model="activeTab" :options="tabOptions"/>

            <div id="cardCollpase5" class="collapse show" dir="ltr">
                <div id="gender-chart" 
                    class="apex-charts pt-3" 
                    data-colors="#6658dd,#1abc9c,#CED4DC"></div>

                <empty 
                    icon="mdi mdi-chart-bar"
                    title="Loading Chart Data..." v-if="isEmpty"/>
            </div>
        </div>
        <div class="card-disabled" v-if="isLoadingInsights">
            <div class="card-portlets-loader"></div>
        </div>
    </div>
</template>
<script>
import { initBarChart } from '@/utils/charts';

export default {
    name: 'GenderChart',

    props: ['insights', 'isLoadingInsights'],

    data() {
        return {
            activeTab: 'age_gender',

            intervals: [
                'Monthly', 'Weekly', 'Daily'
            ],
            params: {
                interval: 'Monthly'
            },
            chart: null,
            colors: ['#6658dd', '#1abc9c', '#f672a7']
        }
    },

    computed: {
        tabOptions() {
            return [
                { id: 'age_gender', name: 'Age & Gender' },
                { id: 'location', name: 'Location' },
                { id: 'intended_shoppers', name: 'Intended Shoppers' },
                { id: 'interests', name: 'Interests' }
            ]
        },

        isEmpty() {
            return this.categories.length === 0 || this.series.length === 0
        },

        categories() {
            if (this.insights && this.insights.data) {
                return Object.keys(this.insights.data)
            }
            return []
        },

        series() {
            let data = {};

            for (const category of this.categories) {
                const point = this.insights.data[category];
                for (const key of Object.keys(point)) {
                    if (data[key] === undefined) {
                        data[key] = { 
                            name: this.$options.filters.titleCase(key), 
                            data: [] 
                        }
                    }

                    data[key].data.push(point[key])
                }
            }

            data = Object.values(data);

            return data;
        },

        getColors() {
            if (this.series.length === 3) {
                return this.colors;
            }

            if (this.series.length === 2) {
                const colors = ['', ''];

                switch (this.series[0].name) {
                    case 'Male':
                        colors[0] = this.colors[0];
                        break;
                    case 'Female':
                        colors[0] = this.colors[1];
                        break;
                    default:
                        colors[0] = this.colors[2];
                        break;
                }

                switch (this.series[1].name) {
                    case 'Male':
                        colors[1] = this.colors[0];
                        break;
                    case 'Female':
                        colors[1] = this.colors[1];
                        break;
                    default:
                        colors[1] = this.colors[2];
                        break;
                }

                return colors;
            }

            if (this.series.length === 1) {
                switch (this.series[0].name) {
                    case 'Male':
                        return [this.colors[0]];
                    case 'Female':
                        return [this.colors[1]];
                    default:
                        return [this.colors[2]];
                }
            }
        },

        chartTitle() {
            return `${Number(this.insights.total).toFixed(2)} % of Woonmi Users`
        }
    },

    watch: {
        insights: {
            handler() {
                this.$nextTick(() => {
                    this.drawChart()
                })
            },
            deep: true,
            immediate: true,
        }
    },

    methods: {
        drawChart() {
            if (this.isEmpty) return;

            if (this.chart) {
                this.chart.updateOptions({
                    colors: this.getColors,
                    xaxis: {
                        categories: this.categories
                    },
                    series: this.series,
                    title: {
                        text: this.chartTitle
                    }
                })
            } else {
                this.chart = initBarChart('#gender-chart', {
                    colors: this.getColors,
                    series: this.series,
                    categories: this.categories,
                    yAxisTitle: '% Of Users On Woonmi',
                    title: {
                        text: this.chartTitle
                    },
                    formatTooltip: {
                        x: (val) => Number(val).toFixed(2) + '%',
                        y: (val) => `Age Group: ${val}`
                    }
                })
            }
        }
    },

    mounted() {
        this.drawChart()
    }
}
</script>