import Service from '@/services/products_features.js';
import Vue from 'vue';

const state = {
    brands: [],
    colors: [],
    sizes: [],
    materials: [],
    preferences: [],
    categories: []
};

const getters = {
    getBrands(state) {
        return state.brands;
    },
    getColors(state) {
        return state.colors;
    },
    getSizes(state) {
        return state.sizes;
    },
    getMaterials(state) {
        return state.materials;
    },
    getPreferences(state) {
        return state.preferences;
    },
    getCategories(state) {
        return state.categories;
    }
};

const mutations = {
    SET(state, { type, payload }) {
        let all = payload.data ? payload.data : payload;
        Vue.set(state, type, all);
    }
};

const actions = {
    index({ commit }, {type, params}) {
        return Service.index(type, params)
            .then(response => {
                commit('SET', { type, payload: response });
                return Promise.resolve(response);
            })
            .catch(error => Promise.reject(error));
    }
};

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
}