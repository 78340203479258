<template>
    <modal
        :show="show"
        :centered="true"
        :maxHeight="true"
        :md="true"
        okText="Create"
        @cancel="cancel"
        okIcon="mdi mdi-plus"
        @ok="submit">
    
        <h5 class="m-0" slot="header">Create New Catalog</h5>

        <div class="" slot="body">
            <div class="row">
                <div class="col-12">
                    <div class="mb-4">
                        <base-input 
                            label="Catalog Name" 
                            v-model="payload.name" 
                            placeholder="Enter name"/>
                    </div>
    
                    <div class="row mb-4">
                        <div class="col-6">
                            <base-select
                                label="Catalog Type"
                                name="type"
                                v-model="payload.type">
                                <option value="">Select Type</option>
                                <option v-for="(type, index) in types" :key="index" :value="type.id">
                                    {{ type.name }}
                                </option>
                            </base-select>
                        </div>
    
                        <div class="col-6">
                            <base-select
                                label="Default Currency"
                                name="currency"
                                v-model="payload.currency">
                                <option value="">Select currency</option>
                                <option class="text-capitalize" v-for="(currency, index) in currencies" :key="index" :value="currency.id">
                                    {{ currency.name }}
                                </option>
                            </base-select>
                        </div>
                    </div>

                    <div class="mb-4">
                        <base-textarea 
                            label="Description" 
                            v-model="payload.description" 
                            placeholder="Enter description"/>
                    </div>
                </div>
            </div>
        </div>
    </modal>
    </template>
    <script>
    import config from '@/config.js';
    import { mapActions, mapGetters } from 'vuex';
    
    export default {
        props: ['show'],
    
        data() {
            return {
                payload: {
                    name: '',
                    type: '',
                    currency: '',
                    description: ''
                }
            }
        },
    
        computed: {
            ...mapGetters({
            }),

            types() {
                return config.catalogs.types;
            },

            currencies() {
                return config.currencies;
            }
        },
    
        watch: {
            show() {
                this.reset()
            },
        },
    
        methods: {
            ...mapActions({
                store: 'Catalogs/store',
            }),
    
            reset() {
                for (let key of Object.keys(this.payload)) {
                    this.payload[key] = '';
                }
            },
    
            cancel() {
                this.reset();
                this.$emit('cancel');
            },
    
            submit() {
                this.showLoader('Creating Catalog... Please wait');
    
                this.store(this.payload)
                    .then(response => {
                        this.hideLoader()
                        this.cancel();

                        setTimeout(() => {
                            this.$router.push({
                                name: `catalogs.${response.type}`, 
                                query: { catalog: response.id }
                            });
                        }, 0);
                    })
                    .catch(error => {
                        this.hideLoader()
                    })
            }
        }
    }
    </script>