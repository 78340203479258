<template>
    <div class="card project-box">
        <div class="card-body">
            <div class="dropdown float-end">
                <a href="#" class="dropdown-toggle card-drop arrow-none" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal m-0 text-muted h3"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item" href="#">Edit</a>
                    <a class="dropdown-item" href="#">Archive</a>
                </div>
            </div> <!-- end dropdown -->
            <!-- Title-->
            <h4 class="mt-0">
                <router-link :to="detailsRoute" href="#" class="text-dark">
                    {{ catalog.name }}
                </router-link>
            </h4>
            <p class="text-muted text-uppercase"><i class="mdi mdi-account-circle"></i> <small>
                {{ catalog.created_by.username }}
            </small></p>
            <div class="badge bg-soft-secondary text-secondary mb-3 text-capitalize">
                {{ catalog.type }}
            </div>
            <div class="badge bg-soft-success text-success mb-3 text-capitalize ms-1" v-if="catalog.status === 'active'">
                Active
            </div>
            <div class="badge bg-soft-danger text-danger mb-3 text-capitalize ms-1" v-if="catalog.status === 'archived'">
                Archived
            </div>
            <!-- Desc-->
            <p class="text-muted font-13 mb-2 sp-line-2">
                {{ catalog.description }}
            </p>
            <!-- Task info-->
            <p class="mb-2">
                <span class="pe-2 text-nowrap d-inline-flex align-items-center">
                    <i class="mdi mdi-shopping text-muted me-1"></i>
                    <span class="fs-6 text-muted"> {{ catalog.items_count || 0 }} Products</span>
                </span>

                <span class="text-nowrap d-inline-flex align-items-center">
                    <i class="mdi mdi-clock-outline text-muted me-1"></i>
                    <span class="fs-6 text-muted">Updated {{ catalog.updated_at | fromNow }}</span>
                </span>
            </p>

            <div class="avatar-group mb-3" id="prod-imgs">
                <a 
                    v-for="(file, index) in files" 
                    href="javascript: void(0);" 
                    class="avatar-group-item">
                    <img :src="file.url" 
                        class="rounded-circle avatar-sm" 
                        :alt="file.product.name" 
                        data-bs-container="#prod-imgs" 
                        data-bs-toggle="tooltip" 
                        data-bs-placement="bottom" 
                        :title="file.product.name" />
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        catalog: {
            type: Object,
            required: true
        }
    },

    computed: {
        files() {
            return this.catalog.files || [];
        },

        detailsRoute() {
            return {
                name: `catalogs.products`, 
                query: { 
                    catalog: this.catalog.id 
                }
            };
        }
    },

    mounted() {
        // console.log(this.detailsRoute)
    }
}
</script>