<template>
    <div class="p-2">
        <h5 class="text-uppercase mt-0 mb-3 bg-light p-2">
            AdCopy (At least 1 image/video required)
        </h5>

        <div class="row">
            <div class="col-md-12">
                <div class="p-1 sticky-container" style="top:50px">
                    <div class="dropzone" id="ad-copy" >
                        <div class="fallback">
                            <input name="file" type="file" multiple />
                        </div>

                        <div class="dz-message needsclick">
                            <i class="h1 text-muted dripicons-cloud-upload"></i>
                            <h5 class="text-muted">Drop files here or click to upload.</h5>
                        </div>

                        <div class="dz-preview dz-image-preview" v-for="(mediaFile, index) in existingFiles" :key="index"> 
                            <div class="dz-image">
                                <img alt="" :src="mediaFile.url" class="h-100 w-100 object-fit-cover"/>
                            </div> 
                            <div class="dz-details"> 
                                <div class="dz-size">
                                    <span class="font-xs">
                                        Uploaded 3 minutes ago
                                    </span>
                                </div> 
                                <div class="dz-filename">
                                    <span>Existing File</span>
                                </div> 
                            </div> 
                            <a class="dz-remove text-danger" href="#" @click.prevent="removeExistingFile(mediaFile)">
                                Unlink
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { initDropzone } from '@/utils/forms';

export default {
    props: ['value', 'mediaFiles', 'deletedFiles'],

    data() {
        return {
        }
    },

    computed: {
        ...mapGetters({
            formValues: 'ProductsCreate/getFormValues'
        }),

        existingFiles() {
            return this.mediaFiles.filter(file => !this.deletedFiles.includes(file.id));
        },
    },

    methods: {
        ...mapActions({
            setFormValue: 'ProductsCreate/setFormValue'
        }), 

        removeExistingFile({id}) {
            this.$emit('remove', id);
        },

        setDropzone(dropzone = null) {
            this.$emit('input', dropzone ? dropzone : this.value);
        },

        onAddedFile(file) {
            this.setDropzone();
        },

        onRemovedFile(file) {
            this.setDropzone();
        }
    },

    mounted() {
        if (this.value) {
            return;
        }
        
        const dropzone = initDropzone('#ad-copy', {
            onAddedFile: this.onAddedFile,
            onRemovedFile: this.onRemovedFile
        });

        this.setDropzone(dropzone);
    }
}
</script>