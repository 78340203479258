import Portal from '@/views/common/layouts/Auth.vue';

let routes = [
    {
        path: '/common',
        name: 'common_module',
        component: Portal,
        meta: {
            requiresAuth: true
        },
        children: require('./common').default
    },
    {
        path: '/merchant',
        name: 'merchant_module',
        component: Portal,
        meta: {
            requiresAuth: true
        },
        children: require('./merchant').default
    }
];

export default routes;