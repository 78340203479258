<template>
    <div class="">
        <label :for="name" class="form-label">
            {{ label }} <span class="text-danger" v-if="required">*</span></label>

        <input 
            :id="name" 
            type="text" 
            :name="name" 
            class="selectize-close-btn"
            :placeholder="placeholder"
            :value="selectizeValue">
    </div>
</template>
<script>
export default {
    props: {
        name: {
            type: String,
            required: true
        },
        required: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        value: {
            type: Array,
            default: () => []
        },
        options: {
            type: Object,
            default: () => ({})
        },
        placeholder: {
            type: String,
            default: ''
        },
        searchUrl: {
            type: String,
            default: ''
        },
        valueField: {
            type: String,
            default: 'name'
        },
        labelField: {
            type: String,
            default: 'name'
        },
        searchField: {
            type: Array,
            default: () => ['name', 'description']
        },
        delimiter: {
            type: String,
            default: ','
        },
        query: {
            type: Object,
            default: () => ({})
        },
        maxLenght: {
            type: Number,
            default: () => null
        },
        itemClass: {
            type: String,
            default: ''
        }
    },

    computed: {
        selectizeValue() {
            return this.value.join(',')
        },
        selectizeOptions() {
            return {
                valueField: this.valueField,
                labelField: this.labelField,
                searchField: this.searchField,
                placeholder: this.placeholder,
                searchUrl: this.searchUrl,
                onChange: this.onChange,
                delimiter: this.delimiter,
                maxItems: this.maxLenght,
                itemClass: this.itemClass,
                getQuery: () => this.query
            }
        }
    },

    methods: {
        onChange({ name, value }) {
            this.$emit('input', value.split(this.delimiter));
        }
    },

    mounted() {
        // console.log(this.selectizeOptions)
        this.initSelectize(`#${this.name}`, this.selectizeOptions);
    }
}
</script>