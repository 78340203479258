<template>
    <div class="p-2">
        <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">Features</h5>
        <div class="row">
            <div class="col-md-3">
                <ul class="list-group sticky-container">
                    <li v-for="(item, index) in features" 
                        :key="index" 
                        class="list-group-item btn btn-light d-flex justify-content-between align-items-center cursor-pointer"  
                        :class="{'text-danger': activeTab === item.key}"
                        @click.prevent="activeTab = item.key">
                        <span>
                            <i class="mdi me-1" :class="item.icon"></i>
                            {{ item.name }}
                        </span>
                        <span class="badge bg-soft-secondary text-secondary p-1 rounded-pill"
                            :class="{'bg-soft-success text-success': featureCounts[item.key] > 0}">
                            {{ featureCounts[item.key] }}
                        </span>
                    </li>
                </ul>    
            </div>
    
            <div class="col-md-9 border-start">
                <div class="bg-white sticky-container p-1 mb-3" :class="{'elevation': selectedItems.length > 0}">
                    <form class="search-bar mb-2">
                        <div class="position-relative">
                            <input  
                                type="text" class="form-control form-control-white" 
                                placeholder="Search by keyword..."
                                v-model="keyword">
                            <span class="mdi mdi-magnify"></span>
                        </div>
                    </form>
        
                    <div class="d-flex flex-nowrap overflow-auto gap-2">
                        <feature-item 
                            v-for="(item, index) in selectedItems"
                            :key="index"
                            :item="item" 
                            :active="true"
                            :activeTab="activeTab" 
                            @selectItem="selectItem"/>
                    </div>
                </div>
        
                <template v-if="items.length">
                    <div class="row">
                        <div class="col-md-6" v-for="(item, index) in items" :key="index">
                            <feature-item 
                                :key="index"
                                :item="item" 
                                :activeTab="activeTab" 
                                @selectItem="selectItem"/>
                        </div>
                    </div>
                </template>
        
                <empty v-if="!items.length" :title="emptyTitle" :icon="emptyIcon"/>
            </div>
        </div>
    </div>
</template>
<script>
import { debounce, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import FeatureItem from './FeatureItem.vue';

export default {
    components: {
        FeatureItem
    },

    data() {
        return {
            keyword: '',
            cachedKeywords: {},
            activeTab: 'categories',

            features: [
                { key: 'categories', name: 'Categories', icon: 'mdi-tag' },
                { key: 'brands', name: 'Brands', icon: 'mdi-tag' },
                { key: 'materials', name: 'Materials', icon: 'mdi-texture' },
                { key: 'sizes', name: 'Sizes', icon: 'mdi-ruler' },
                { key: 'colors', name: 'Colors', icon: 'mdi-palette' },
                { key: 'preferences', name: 'Preferences', icon: 'mdi-cube'}
            ]
        }
    },

    computed: {
        ...mapGetters({
            productTypes: 'ProductTypes/getAll',
            formValues: 'ProductsCreate/getFormValues',

            categories: 'ProductsFeatures/getCategories',
            brands: 'ProductsFeatures/getBrands',
            materials: 'ProductsFeatures/getMaterials',
            sizes: 'ProductsFeatures/getSizes',
            colors: 'ProductsFeatures/getColors',
            preferences: 'ProductsFeatures/getPreferences'
        }),

        items() {
            return get(this, this.activeTab, [])
        },

        featureCounts () {
            return {
                brands: this.formValues.brands.length,
                materials: this.formValues.materials.length,
                sizes: this.formValues.sizes.length,
                colors: this.formValues.colors.length,
                preferences: this.formValues.preferences.length,
                categories: this.formValues.categories.length
            }
        },

        emptyTitle() {
            if (!this.keyword) {
                return `Enter a keyword to search for ${this.activeTab}`
            }

            return `No item matches your search keyword "${this.keyword}"`
        },

        emptyIcon() {
            if (this.keyword) {
                return 'mdi mdi-emoticon-sad-outline'
            } 

            return 'mdi mdi-emoticon-happy-outline'
        },

        selectedItems() {
            return get(this.formValues, this.activeTab, [])
        }
    },

    watch: {
        keyword: debounce(function() {
            this.search()
        }, 100),

        activeTab(newValue, oldValue) {
            this.cachedKeywords[oldValue] = this.keyword;
            this.keyword = this.cachedKeywords[newValue] || '';
        }
    },

    methods: {
        ...mapActions({
            index: 'ProductsFeatures/index',
            setFormValue: 'ProductsCreate/setFormValue'
        }),

        selectItem(item) {
            const items = [...this.selectedItems];
            const index = items.findIndex(i => i.id === item.id);

            if (index > -1) {
                items.splice(index, 1);
            } else {
                items.push(item);
            }

            this.setFormValue({key: this.activeTab, value: items})
        },

        search() {
            this.index({
                type: this.activeTab, 
                params: {
                    keyword: this.keyword
                }
            })
            .then(res => null)
            .catch(error => null)
        }
    }
}
</script>