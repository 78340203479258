<template>
    <div>
        <custom-modal title="Create Coupon" @cancel="cancel" v-if="show">
            <div class="row">
                <div class="col-6">
                    <base-input 
                        label="Name" 
                        typeNumber="3"
                        placeholder="e.g Early Bird Discount" 
                        v-model="payload.name"
                    />
                </div>
                <div class="col-6">
                    <base-input 
                        label="Code" 
                        typeNumber="3"
                        placeholder="e.g EARLYBIRD24" 
                        v-model="payload.code"
                    />
                </div>
            </div>
        </custom-modal>
    </div>
</template>
<script>
export default {
    props: ['show'],

    data() {
        return {
            payload: {
                name: '',
                code: ''
            }
        }
    },

    methods: {
        cancel() {
            this.$emit('cancel');
        }
    }
}
</script>