<template>
    <div>
        <div class="row">
            <div class="col-md-12 mb-2">
                <gender 
                    :isLoadingInsights="isLoadingInsights"
                    :insights="ageAndGender"
                    />
            </div>
            <div class="col-md-12">
                <categories :isLoadingInsights="isLoadingInsights"/>
            </div>
        </div>
    </div>
</template>
<script>
import { get } from 'lodash';
import Gender from './charts/Gender.vue';
import Categories from './charts/Categories.vue';

export default {
    name: 'InsightCharts',
    props: ['isLoadingInsights', 'insightsData'],
    components: {
        Gender,
        Categories
    },
    data() {
        return {
            dateRange: ''
        }
    },
    computed: {
        ageAndGender() {
            return get(this.insightsData, 'age_and_gender', null);
        }
    },
    watch: {
        insightsData: {
            handler() {
                // console.log('age and gender from insightCharts', this.ageAndGender)
            },
            deep: true,
            immediate: true
        }
    }
}
</script>