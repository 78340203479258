<template>
    <div @click="closeSidebar">
        <slot />
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    name: 'ContentWrapper',
    
    computed: {
        ...mapGetters({
            sidebarOpened: 'getSidebarOpened',
        })
    },

    methods:{
        ...mapActions({
            closeSidebar: 'closeSidebar'
        })
    }
}
</script>

<style>

</style>
