<template>
    <div class="p-2">
        <h5 class="text-uppercase mt-0 mb-3 bg-light p-2">
            Images (At least 1 image required)
        </h5>

        <div class="row">
            <div class="col-md-12">
                <div class="p-1 sticky-container" style="top:50px">
                    <div class="dropzone" id="prod-images" >
                        <div class="fallback">
                            <input name="file" type="file" multiple />
                        </div>

                        <div class="dz-message needsclick">
                            <i class="h1 text-muted dripicons-cloud-upload"></i>
                            <h3 class="text-muted">Drop files here or click to upload.</h3>
                        </div>

                        <div class="dz-preview dz-image-preview" v-for="(mediaFile, index) in mediaFiles" :key="index"> 
                            <div class="dz-image">
                                <img alt="" :src="mediaFile.url" class="h-100 w-100 object-fit-cover"/>
                            </div> 
                            <div class="dz-details"> 
                                <div class="dz-size">
                                    <span class="font-xs">
                                        Uploaded 3 minutes ago
                                    </span>
                                </div> 
                                <div class="dz-filename">
                                    <span>Existing File</span>
                                </div> 
                            </div> 
                            <a class="dz-remove text-danger" href="#" @click.prevent="removeExistingFile(mediaFile)">
                                Unlink
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="d-flex gap-2">
                    <div class="p-2 elevation" v-for="(image, index) in all" :key="index">
                        <img :src="image.url" class="img-fluid object-fit-cover avatar-xl" :alt="image.name">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { initDropzone } from '@/utils/forms';

export default {
    data() {
        return {
            dropzone: null
        }
    },

    computed: {
        ...mapGetters({
            formValues: 'ProductsCreate/getFormValues'
        }),
        all() {
            return this.formValues.additionalImages;
        },
        colors() {
            return this.formValues.colors;
        },
        mediaFiles() {
            return this.formValues.media_files.filter(file => !this.formValues.deleted_media_files.includes(file.id));
        }
    },

    methods: {
        ...mapActions({
            setFormValue: 'ProductsCreate/setFormValue'
        }), 

        removeExistingFile({id}) {
            this.setFormValue({ key: 'deleted_media_files', value: [...this.formValues.deleted_media_files, id] });
        },

        setDropzone() {
            this.setFormValue({ key: 'dropzone', value: this.dropzone });
        },

        onAddedFile(file) {
            this.setDropzone();
        },

        onRemovedFile(file) {
            this.setDropzone();
        }
    },

    mounted() {
        if (this.formValues.dropzone) {
            console.log('dropzone already exists', this.formValues.dropzone);
            this.dropzone = this.formValues.dropzone;
        } else {
            this.dropzone = initDropzone('#prod-images', {
                onAddedFile: this.onAddedFile,
                onRemovedFile: this.onRemovedFile
            });

            this.setDropzone();
        }
    }
}
</script>