import Vue from 'vue';
import { get } from 'lodash';

const INITIAL_STATE = {
    formValues: {
        id: '',
        name: '',
        gid_type: '',
        gid: '',
        sku: '',
        price: '',
        status: '',
        condition: '',
        description: '',
        objective: '',

        categories: [],
        brands: [],
        colors: [],
        sizes: [],
        materials: [],
        preferences: [],
        
        keywords: [],
        claims: [],
        
        dropzone: null,
        presignedUrls: null,
        media_files: [],
        deleted_media_files: []
    }
};

const state = { ...INITIAL_STATE }

const getters = {
    getFormValues(state) {
        return { ...state.formValues }
    },

    getFormValuesReqPayload(state) {
        const values = { ...state.formValues };
        const data = {
            id: values.id,
            name: values.name,
            gid_type: values.gid_type,
            gid: values.gid,
            sku: values.sku,
            price: values.price,
            status: values.status,
            condition: values.condition,
            description: values.description,

            keywords: values.keywords,
            claims: values.claims,
            objective: values.objective,

            features: {
                brands: values.brands.map(brand => brand.id),
                categories: values.categories.map(category => category.id),
                colors: values.colors.map(color => color.id),
                sizes: values.sizes.map(size => size.id),
                materials: values.materials.map(material => material.id),
                preferences: values.preferences.map(preference => preference.id),
            },

            deleted_media_files: values.deleted_media_files
        };

        if (values.dropzone && values.dropzone.files.length > 0) {
            data.images = values.dropzone.files.map(file => {
                return {
                    id: file.upload.uuid,
                    mime: file.type, 
                }
            });
        }

        return data;
    }
};

const mutations = {
    SET_FORM_VALUE(state, { key, value }) {
        Vue.set(state.formValues, key, value);
    },

    CLEAR_STATE(state) {
        state = { ...INITIAL_STATE }
    },

    SEED_PRODUCT(state, product) {
        for (let key of Object.keys(state.formValues)) {
            Vue.set(state.formValues, key, get(product, key, ''));
        }

        Vue.set(state.formValues, 'deleted_media_files', get(product, 'deleted_media_files', []));
        Vue.set(state.formValues, 'objective', get(product, 'objective.id', get(product, 'objective._id', '')));

        const features = ['brands', 'categories', 'colors', 'sizes', 'materials', 'preferences'];

        for (let feature of features) {
            Vue.set(state.formValues, feature, get(product, `features.${feature}`, []));
        };
    }
};

const actions = {
    setFormValue({ commit }, payload) {
        commit('SET_FORM_VALUE', payload);
    },

    setFormValues({ commit }, payload) {
        for (let key of Object.keys(payload)) {
            commit('SET_FORM_VALUE', { key, value: payload[key] });
        }
    },

    clearState({commit}) {
        commit('CLEAR_STATE')
    },

    seedProduct({commit}, channel) {
        commit('SEED_PRODUCT', channel)
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}